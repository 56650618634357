import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: window.location.pathname.startsWith('/na') ? 'na' : 'en',
    fallbackLng: 'en',
    preload: ['de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'ru'],
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    backend: {
      loadPath: () => '/locales/{{lng}}/{{ns}}.json',
    },
  })

export default i18n
