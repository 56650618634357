import { useFormContext } from 'react-hook-form'

import { useCalculator } from '../../../../hooks/useCalculator'
import { useProjectedSavingsData } from '../../ProjectedSavings/hooks/useProjectedSavingsData'

const useProjectedProductionData = () => {
  const { watch } = useFormContext()
  const { multiply, sum } = useCalculator()
  const { totalProjectedAnnualSavings } = useProjectedSavingsData()

  const formValues = watch([
    'caries.fee',
    'caries.volumeIncrease',
    'restorative.fee',
    'restorative.volumeIncrease',
    'orthodontics.fee',
    'orthodontics.volumeIncrease',
    'invisalign.fee',
    'invisalign.volumeIncrease',
  ])

  const additionalCariesRevenue = multiply([
    formValues['caries.volumeIncrease'],
    formValues['caries.fee'],
  ])

  const restorativeIncrease = multiply([
    formValues['restorative.volumeIncrease'],
    formValues['restorative.fee'],
  ])

  const orthodonticsIncrease = multiply([
    formValues['orthodontics.volumeIncrease'],
    formValues['orthodontics.fee'],
  ])

  const invisalignIncrease = multiply([
    formValues['invisalign.volumeIncrease'],
    formValues['invisalign.fee'],
  ])

  const projectedAnnualIncrease = sum([
    restorativeIncrease,
    orthodonticsIncrease,
    invisalignIncrease,
  ])

  const average5dMonthlySavings =
    sum([
      sum([projectedAnnualIncrease, additionalCariesRevenue]),
      totalProjectedAnnualSavings,
    ]) / 12

  const averageFlexMonthlySavings =
    sum([projectedAnnualIncrease, totalProjectedAnnualSavings]) / 12

  return {
    additionalCariesRevenue,
    restorativeIncrease,
    orthodonticsIncrease,
    invisalignIncrease,
    projectedAnnualIncrease,
    average5dMonthlySavings,
    averageFlexMonthlySavings,
  }
}

export { useProjectedProductionData }
