import React from 'react'
import { FormContext, useForm } from 'react-hook-form'

import Footer from '../Footer'
import Header from '../../Header'
import Nav from '../Nav'
import { useCalculatorState } from '../../../context/calculator'

const NAWrapper = ({ children, routes }) => {
  const { initialValues, ...state } = useCalculatorState()

  const methods = useForm({
    defaultValues: state,
  })

  return (
    <FormContext {...methods}>
      <Header resetForm={() => methods.reset(state)} />
      <div className="max-w-screen-xl mx-auto pb-24 px-6 pt-12">
        <Nav routes={routes} />
        {children}
        <Footer routes={routes} />
      </div>
    </FormContext>
  )
}

export default NAWrapper
