import React, { memo } from 'react'

import { ReactComponent as Calendar } from '../../svg/calendar.svg'
import { ReactComponent as Clock } from '../../svg/clock.svg'
import { ReactComponent as Package } from '../../svg/package.svg'
import { ReactComponent as Piggy } from '../../svg/piggy.svg'

const Icon = ({ className, svg = 'clock' }) => {
  switch (svg) {
    case 'calendar':
      return <Calendar className={className} />
    case 'clock':
    default:
      return <Clock className={className} />
    case 'package':
      return <Package className={className} />
    case 'piggy':
      return <Piggy className={className} />
  }
}

export default memo(Icon)
