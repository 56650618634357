import React from 'react'
import ReactSelect from 'react-select'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../tailwind.config.js'

const fullConfig = resolveConfig(tailwindConfig)

const Select = ({
  className,
  disabled,
  onChange,
  options,
  placeholder,
  value,
}) => {
  const customStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderColor: isFocused && fullConfig.theme.colors['port-gore'],
      boxShadow:
        isFocused && `0 0 0 1px ${fullConfig.theme.colors['port-gore']}`,

      ':hover': {
        ...styles[':hover'],
        borderColor: fullConfig.theme.colors['port-gore'],
      },
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? fullConfig.theme.colors['port-gore']
        : isFocused
        ? fullConfig.theme.colors['gray-nurse']
        : 'white',
      color: isSelected ? 'white' : fullConfig.theme.colors['port-gore'],
    }),
    singleValue: (styles) => ({
      ...styles,
      color: fullConfig.theme.colors['port-gore'],
    }),
  }

  return (
    <ReactSelect
      className={className}
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      styles={customStyles}
      isDisabled={disabled}
    />
  )
}

export default Select
