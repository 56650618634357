import React, { memo } from 'react'
import { number, oneOf, oneOfType, string } from 'prop-types'
import cx from 'classnames'

import Icon from '../Icon'

const SummaryCircle = ({
  className,
  icon,
  label,
  note,
  theme = 'purple',
  value,
}) => {
  const themeClass = (theme) => {
    switch (theme) {
      case 'turqoise':
        return 'bg-turqoise text-starship'
      case 'yellow':
        return 'bg-starship text-turqoise'
      case 'violet':
        return 'bg-gradient-to-br to-affair from-bossanova text-white'
      case 'purple':
      default:
        return 'bg-port-gore text-turqoise'
    }
  }

  return (
    <div className={cx('mx-auto w-72', className)}>
      <div
        className={cx(
          'flex flex-col h-72 items-center justify-center px-10 py-8 rounded-full text-center w-full',
          themeClass(theme)
        )}
      >
        {icon && <Icon className="mb-2 w-16" svg={icon} />}
        <p className="break-all font-bold text-3xl">{value}</p>
        <p
          className={cx('font-bold', {
            'text-white': theme !== 'yellow',
            'text-black': theme === 'yellow',
          })}
        >
          {label}
        </p>
      </div>
      {note && (
        <p
          className="italic mt-4"
          dangerouslySetInnerHTML={{
            __html: note,
          }}
        />
      )}
    </div>
  )
}

SummaryCircle.propTypes = {
  className: string,
  label: string.isRequired,
  note: string,
  theme: oneOf(['purple', 'turqoise', 'violet', 'yellow']),
  value: oneOfType([number, string]).isRequired,
}

export default memo(SummaryCircle)
