import React from 'react'
import { ErrorMessage, useFormContext } from 'react-hook-form'
import cx from 'classnames'

import FormError from './Error'
import FormLabel from './Label'

const Input = ({
  disabled = false,
  hidden = false,
  label,
  name,
  permitSpaces = false,
  placeholder,
  type = 'text',
}) => {
  const { errors, register } = useFormContext()

  const hasError = errors[name]

  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' '))
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '')
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        {label && <FormLabel label={label} for={name} />}
        <input
          className={cx(
            'appearance-none border focus:border-turqoise focus:outline-none px-4 py-2 rounded w-full',
            {
              'border-red-600': hasError,
              'cursor-not-allowed opacity-50': disabled,
            }
          )}
          name={name}
          id={name}
          type={hidden ? 'hidden' : type}
          placeholder={placeholder || label}
          disabled={disabled}
          ref={register}
          onChange={permitSpaces ? handleChange : null}
        />
      </div>
      <ErrorMessage as={<FormError />} name={name} errors={errors} />
    </div>
  )
}

export default Input
