import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SectionHeader from '../../SectionHeader'
import SectionRow from '../../SectionRow'
import SectionTitle from '../../SectionTitle'
import SummaryCircle from '../../SummaryCircle'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleDispatch, useLocaleState } from '../../../context/locale'

const DentalTreatments = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()
  const { currencyFormat } = useLocaleDispatch()
  const { activeLanguage } = useLocaleState()
  const { t } = useTranslation(['translation', 'treatments'])

  const isRussianCalculator = activeLanguage.value === 'ru'
  const isLatamCalculator = activeLanguage.value === 'pt' || activeLanguage.value === 'es'


  const formValues = watch([
    'restorative.volume',
    'restorative.fee',
    'orthodontics.volume',
    'orthodontics.fee',
    'invisalign.volume',
    'invisalign.fee',
    'caries.volume',
    'caries.fee',
  ])

  const restorativeProduction = multiply([
    formValues['restorative.volume'],
    formValues['restorative.fee'],
  ])

  const orthodonticsProduction = multiply([
    formValues['orthodontics.volume'],
    formValues['orthodontics.fee'],
  ])

  const invisalignProduction = multiply([
    formValues['invisalign.volume'],
    formValues['invisalign.fee'],
  ])

  const cariesProduction = multiply([
    formValues['caries.volume'],
    formValues['caries.fee'],
  ])

  const totalAnnualProduction = sum([
    restorativeProduction,
    orthodonticsProduction,
    invisalignProduction,
    cariesProduction,
  ])


  const defaultRows = [
    {
      label: t('treatments:table.rows.restorative.label'),
      fields: [
        {
          name: 'restorative.volume',
        },
        {
          name: 'restorative.fee',
        },
        {
          name: restorativeProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
    {
      label: t('treatments:table.rows.orthodontics.label'),
      fields: [
        {
          name: 'orthodontics.volume',
        },
        {
          name: 'orthodontics.fee',
        },
        {
          name: orthodonticsProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
    {
      label: t('treatments:table.rows.invisalign.label'),
      fields: [
        {
          name: 'invisalign.volume',
        },
        {
          name: 'invisalign.fee',
        },
        {
          name: invisalignProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
    ...(isRussianCalculator
      ? []
      : [
        {
          label: t('treatments:table.rows.caries.label'),
          fields: [
            {
              name: 'caries.volume',
            },
            {
              name: 'caries.fee',
            },
            {
              name: cariesProduction,
              readOnly: true,
              type: 'currency',
            },
          ],
        },
      ]),
  ];
  const latamRows = [
    {
      label: t('treatments:table.rows.caries.label'),
      fields: [
        {
          name: 'caries.volume',
        },
        {
          name: 'caries.fee',
        },
        {
          name: cariesProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
    {
      label: t('treatments:table.rows.restorative.label'),
      fields: [
        {
          name: 'restorative.volume',
        },
        {
          name: 'restorative.fee',
        },
        {
          name: restorativeProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
    {
      label: t('treatments:table.rows.invisalign.label'),
      fields: [
        {
          name: 'invisalign.volume',
        },
        {
          name: 'invisalign.fee',
        },
        {
          name: invisalignProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
    {
      label: t('treatments:table.rows.orthodontics.label'),
      fields: [
        {
          name: 'orthodontics.volume',
        },
        {
          name: 'orthodontics.fee',
        },
        {
          name: orthodonticsProduction,
          readOnly: true,
          type: 'currency',
        },
      ],
    },
  ];

  const dentalTreatmentsData = {
    headers: {
      fields: [
        {
          label: t('treatments:table.header.quantity.label'),
        },
        {
          label: t('treatments:table.header.fee.label'),
        },
        {
          label: t('treatments:table.header.production.label'),
        },
      ],
    },
    rows: isLatamCalculator ? latamRows : defaultRows
  }

  return (
    <section className="mb-8">
      <div className="mb-4 md:w-4/6">
        <p className="italic md:text-lg">{t('translation:body.disclaimer')}</p>
      </div>
      <div className="md:-mx-6 md:flex">
        <div className="md:w-4/6 md:px-6 mb-8 md:mb-0 w-full">
          <SectionTitle theme="violet" step={1} title={t('treatments:title')} />
          <form className="flex flex-col">
            {dentalTreatmentsData.headers && (
              <SectionHeader headers={dentalTreatmentsData.headers} />
            )}
            {dentalTreatmentsData.rows &&
              dentalTreatmentsData.rows.map((row, index) => (
                <SectionRow {...row} key={index} />
              ))}
          </form>
        </div>
        <div className="md:w-2/6 md:px-6 w-full">
          <SummaryCircle
            theme="violet"
            label={t('treatments:summary.production.label')}
            value={currencyFormat({ value: totalAnnualProduction })}
            icon="package"
          />
        </div>
      </div>
    </section>
  )
}

export default DentalTreatments
