import { useFormContext } from 'react-hook-form'

import { useCalculator } from '../../../../hooks/useCalculator'
import { useLocaleState } from '../../../../context/locale'

const useTotalCostOwnershipData = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()
  const { activeCurrency } = useLocaleState()

  const formValues = watch([
    'crown.fee',
    'crown.perUnit',
    'crown.volume',
    'crown.volumeIncrease',
    'implants.fee',
    'implants.perUnit',
    'implants.volume',
    'implants.volumeIncrease',
    'night.fee',
    'night.perUnit',
    'night.volume',
    'night.volumeIncrease',
    'orthodontics.fee',
    'orthodontics.perUnit',
    'orthodontics.volume',
    'orthodontics.volumeIncrease',
    'veneers.fee',
    'veneers.perUnit',
    'veneers.volume',
    'veneers.volumeIncrease',
    'remakes.perUnit',
    'remakes.volume',
    'storage.perUnit',
    'scanner.usd.oneYear',
    'scanner.cad.oneYear',
    'scanner.usd.sleeveFee',
    'scanner.cad.sleeveFee',
    'scanner.usd.fiveYear',
    'scanner.cad.fiveYear',
    'service.usd.fiveYear',
    'service.cad.fiveYear',
  ])

  const totalScannerSleeves = (year) => {
    const values = [
      'crown',
      'implants',
      'night',
      'orthodontics',
      'veneers',
    ].map((key) =>
      sum([formValues[`${key}.volume`], formValues[`${key}.volumeIncrease`]])
    )

    const total = sum([...values, formValues['remakes.volume']])

    return Math.ceil(
      multiply([
        multiply([
          total,
          formValues[`scanner.${activeCurrency.value}.sleeveFee`],
        ]),
        year,
      ])
    )
  }

  const year1Total = sum([
    totalScannerSleeves(1),
    formValues[`scanner.${activeCurrency.value}.oneYear`],
  ])

  const year5Total = sum([
    totalScannerSleeves(5),
    formValues[`scanner.${activeCurrency.value}.fiveYear`],
    formValues[`service.${activeCurrency.value}.fiveYear`],
  ])

  const year1Savings = () => {
    const savingsValues = [
      'crown',
      'implants',
      'night',
      'orthodontics',
      'remakes',
      'veneers',
    ].map((key) => ({
      perUnit: formValues[`${key}.perUnit`],
      volume: formValues[`${key}.volume`],
    }))

    const savingsTotal = [
      ...savingsValues.map(({ perUnit, volume }) =>
        multiply([perUnit, volume])
      ),
    ]

    const productionValues = [
      'crown',
      'implants',
      'night',
      'orthodontics',
      'veneers',
    ].map((key) => ({
      fee: formValues[`${key}.fee`],
      volumeIncrease: formValues[`${key}.volumeIncrease`],
    }))

    const increaseProduction = [
      ...productionValues.map(({ fee, volumeIncrease }) =>
        multiply([fee, volumeIncrease])
      ),
    ]

    return sum([
      ...savingsTotal,
      formValues['storage.perUnit'],
      ...increaseProduction,
    ])
  }

  const payback = year5Total / (year1Savings() / 12)

  const profit = multiply([year1Savings(), 5]) - year5Total

  const roi = Math.floor(multiply([profit / year5Total, 100]))

  return {
    totalScannerSleeves,
    year1Total,
    year1Savings,
    year5Total,
    payback,
    profit,
    roi,
  }
}

export { useTotalCostOwnershipData }
