import { useFormContext } from 'react-hook-form'

import { useCalculator } from '../../../../hooks/useCalculator'
import { useLocaleState } from '../../../../context/locale'
import { useProjectedProductionData } from '../../ProjectedProduction/hooks/useProjectedProductionData'

const useTotalOwnershipData = ({ activeSystem }) => {
  const { watch } = useFormContext()
  const { multiply, sum } = useCalculator()
  const { activeCurrency } = useLocaleState()
  const {
    average5dMonthlySavings,
    averageFlexMonthlySavings,
  } = useProjectedProductionData()

  const formValues = watch(['scannerSleeves.volume', 'systemService.cost'])

  const scannerSleevesTotal = multiply([
    formValues['scannerSleeves.volume'],
    activeCurrency.sleeveFee,
  ])

  const totalCostOfOwnership = sum([
    scannerSleevesTotal,
    formValues['systemService.cost'],
  ])

  const calculateProfit = (years = 1) => {
    const savings =
      activeSystem === 'element5d'
        ? average5dMonthlySavings
        : averageFlexMonthlySavings

    return (
      multiply([savings, multiply([years, 12])]) - totalCostOfOwnership || 0
    )
  }

  return { calculateProfit, scannerSleevesTotal, totalCostOfOwnership }
}

export { useTotalOwnershipData }
