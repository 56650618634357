import React from 'react'
import { ErrorMessage, useFormContext } from 'react-hook-form'

import FormError from './Error'

const Checkbox = ({ disabled = false, label, name }) => {
  const { errors, register } = useFormContext()

  return (
    <div className="flex flex-col">
      <label className="block cursor-pointer text-gray-700 font-bold">
        <input
          className="mr-2 leading-tight"
          type="checkbox"
          name={name}
          disabled={disabled}
          ref={register}
        />
        <span>{label}</span>
      </label>
      <ErrorMessage as={<FormError />} name={name} errors={errors} />
    </div>
  )
}

export default Checkbox
