import React from 'react'
import { useTranslation } from 'react-i18next'
import { oneOf, string } from 'prop-types'
import cx from 'classnames'

import SectionTitle from '../SectionTitle'
import { useLocaleDispatch } from '../../context/locale'

const TotalSection = ({ columns, theme = 'purple', title }) => {
  const { currencyFormat, numberFormat, percentFormat } = useLocaleDispatch()
  const { t } = useTranslation()

  const themeClass = (theme) => {
    switch (theme) {
      case 'gray':
        return 'bg-pewter'
      case 'turqoise':
        return 'bg-turqoise'
      case 'purple':
      default:
        return 'bg-port-gore'
    }
  }

  return (
    <div>
      {title && <SectionTitle title={title} />}
      <div className={cx(themeClass(theme))}>
        <div className="md:flex md:justify-between">
          {columns &&
            columns.map(({ label, type = 'number', value }, index) => {
              const formatValue = (value) => {
                switch (type) {
                  case 'currency':
                    return currencyFormat({ value })
                  case 'percent':
                    return percentFormat(value)
                  case 'text':
                    return value
                  case 'number':
                  default:
                    return numberFormat(value)
                }
              }

              return (
                <div
                  className="flex-1 px-4 py-4 md:py-8 text-center text-white"
                  key={index}
                >
                  <p className="mb-4 md:text-5xl text-3xl">
                    {formatValue(value)}
                  </p>
                  <span className="text-xl">{t(label)}</span>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

TotalSection.propTypes = {
  theme: oneOf(['gray', 'turqoise', 'purple']),
  title: string,
}

export default TotalSection
