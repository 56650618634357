import React from 'react'

import SectionHeader from '../../../SectionHeader'
import SectionRow from '../../../SectionRow'

const AdditionalCost = ({ headers, rows, title }) => {
  return (
    <form className="flex flex-col mb-8">
      {headers && <SectionHeader headers={headers} title={{ title }} />}
      {rows && rows.map((row, index) => <SectionRow {...row} key={index} />)}
    </form>
  )
}

export default AdditionalCost
