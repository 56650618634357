import React, { useEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import DentalProcedures from '../DentalProcedures'
import NAWrapper from '../Wrapper'
import ProjectedIncrease from '../ProjectedIncrease'
import ProjectedSavings from '../ProjectedSavings'
import TotalCostOwnership from '../TotalCostOwnership'
import { CalculatorProvider } from '../../../context/calculator'
import { gtmTags } from '../../gtmTags'

const NACalculator = () => {
  const { path } = useRouteMatch()

  useEffect(() => {
    TagManager.initialize({
      gtmId: gtmTags.na,
    })
  }, [])

  const routes = [
    {
      path,
      component: DentalProcedures,
      title: 'Dental Treatments',
    },
    {
      path: `${path}/projected-savings`,
      component: ProjectedSavings,
      title: 'Projected Savings',
    },
    {
      path: `${path}/projected-increase`,
      component: ProjectedIncrease,
      title: 'Projected Increase',
    },
    {
      path: `${path}/total-cost-ownership`,
      component: TotalCostOwnership,
      title: 'Total Cost of Ownership',
    },
  ]

  return (
    <div className="na">
      <CalculatorProvider>
        {routes.map((route) => {
          const Component = route.component

          return (
            <Route exact path={route.path} key={route.path}>
              <NAWrapper routes={routes}>
                <Component />
              </NAWrapper>
            </Route>
          )
        })}
      </CalculatorProvider>
    </div>
  )
}

export default NACalculator
