import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { useForm, FormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import Button from '../Button'
import { countries as countryOptions } from '../../lib/countries'
import { FormCheckbox, FormInput, FormInputNoSpaces, FormSelect } from '../Form'
import { states as stateOptions } from '../../lib/states'
import { useLocaleState } from '../../context/locale'

ReactModal.setAppElement('#root')

const ReportFormModal = ({ onRequestClose, submitForm }) => {
  const { activeLanguage } = useLocaleState()
  const [formState, setFormState] = useState(null)
  const { t } = useTranslation(['modal', 'translation'])

  useEffect(() => {
    if (formState === 'success') {
      const timer = setTimeout(() => {
        onRequestClose()
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [formState, onRequestClose])

  const isRussianCalculator = activeLanguage.value === 'ru'

  const { handleSubmit, watch, ...methods } = useForm({
    defaultValues: {
      sharePartners: false,
      scheduleDemo: true,
      receiveCommunication: true,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      email: yup
        .string()
        .required('Email address is required')
        .email('Email address is not valid'),
      phone: yup.string().required('Phone number is required'),
      country: yup
        .string()
        .required('Country is required')
        .oneOf(countryOptions.map((country) => country.value)),
      state: yup
        .string()
        .when('country', {
          is: 'United States of America',
          then: yup.string().required('State is required'),
        })
        .oneOf(stateOptions.map((state) => state.value)),
      ...(!isRussianCalculator && {
        postal: yup.string().required('Postal code is required'),
      }),
      sharePartners: yup.boolean().oneOf([true], 'This is required'),
    }),
  })

  const formValues = watch(['country'])

  const onSubmit = async (values) => {
    setFormState('loading')
    try {
      await submitForm(values)

      setFormState('success')
    } catch (err) {
      setFormState('error')
    }
  }
  const isError = formState === 'error'
  const isLoading = formState === 'loading'
  const isSuccess = formState === 'success'

  return (
    <ReactModal
      isOpen
      bodyOpenClassName="overflow-hidden"
      className="bg-white md:w-3/5 rounded-lg shadow-xl w-full overflow-scroll"
      overlayClassName="bg-black bg-opacity-75 fixed flex flex-col items-center top-0 left-0 right-0 bottom-0 px-6 py-16"
    >
      <div className="px-6 py-4">
        <header className="items-center justify-between md:flex">
          <h3 className="font-medium mb-4 md:mb-0 text-3xl">
            {t('modal:header.title')}
          </h3>
          <p className="text-gray-700 text-sm"> {t('modal:header.note')}</p>
        </header>
        <hr className="my-4" />
        {isSuccess ? (
          <div className="bg-aquamarine-blue border border-turqoise px-4 py-2 rounded">
            <p className="font-medium text-lg text-white">
              Thank you for your inquiry! We will respond as soon as possible
            </p>
          </div>
        ) : (
          <FormContext {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isLoading && (
                <div className="bg-gray-nurse mb-4 px-4 py-2 rounded">
                  <p className="font-medium">
                    Please wait whilst we generate and send your report
                  </p>
                </div>
              )}
              {isError && (
                <div className="bg-red-400 border border-red-500 mb-4 px-4 py-2 rounded">
                  <p className="font-medium text-white">
                    There was an error sending your report
                  </p>
                </div>
              )}
              <div className="mb-4">
                {isRussianCalculator ? (
                  <FormInputNoSpaces
                    name="name"
                    placeholder={t('modal:form.fields.name.placeholder')}
                    disabled={isLoading}
                  />
                ) : (
                  <FormInput
                    name="name"
                    placeholder={t('modal:form.fields.name.placeholder')}
                    disabled={isLoading}
                  />
                )}
              </div>
              <div className="-mx-2 flex mb-4">
                <div className="md:w-1/2 px-2 w-full">
                  <FormInput
                    name="email"
                    type="email"
                    placeholder={t('modal:form.fields.email.placeholder')}
                    disabled={isLoading}
                  />
                </div>
                <div className="md:w-1/2 px-2 w-full">
                  <FormInput
                    name="phone"
                    type="tel"
                    placeholder={t('modal:form.fields.phone.placeholder')}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="-mx-2 flex mb-4">
                <div className="md:w-1/2 px-2 w-full">
                  <FormInput
                    name="representative"
                    placeholder={t(
                      'modal:form.fields.representative.placeholder'
                    )}
                    disabled={isLoading}
                  />
                </div>
                <div className="md:w-1/2 px-2 w-full">
                  <FormInput
                    name="practice"
                    placeholder={t('modal:form.fields.practice.placeholder')}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="-mx-2 flex mb-4">
                <div className="md:w-1/2 px-2 w-full">
                  <FormSelect
                    name="country"
                    options={countryOptions}
                    placeholder={t('modal:form.fields.country.placeholder')}
                    disabled={isLoading}
                  />
                </div>
                {formValues.country === 'United States of America' && (
                  <div className="md:w-1/2 px-2 w-full">
                    <FormSelect
                      name="state"
                      options={stateOptions}
                      placeholder={t('modal:form.fields.state.placeholder')}
                      disabled={isLoading}
                    />
                  </div>
                )}
              </div>
              {isRussianCalculator ? null : (
                <div className="mb-4">
                  <FormInput
                    name="postal"
                    placeholder={t('modal:form.fields.postal.placeholder')}
                    disabled={isLoading}
                  />
                </div>
              )}
              <div className="mb-4">
                <FormCheckbox
                  name="sharePartners"
                  label={t('modal:form.fields.sharePartners.label')}
                  disabled={isLoading}
                />
              </div>
              <div className="mb-4">
                <FormCheckbox
                  name="scheduleDemo"
                  label={t('modal:form.fields.scheduleDemo.label')}
                  disabled={isLoading}
                />
              </div>
              <div className="mb-4">
                <FormCheckbox
                  name="receiveCommunication"
                  label={t('modal:form.fields.receiveCommunication.label')}
                  disabled={isLoading}
                />
              </div>
            </form>
          </FormContext>
        )}
      </div>
      {!isSuccess && (
        <footer className="bg-gray-nurse px-6 py-4 rounded-b">
          <div className="-mx-2 flex justify-end">
            <div className="px-2">
              <Button onClick={onRequestClose} disabled={isLoading}>
                {t('translation:button.close')}
              </Button>
            </div>
            <div className="px-2">
              <Button onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                {t('translation:button.send')}
              </Button>
            </div>
          </div>
        </footer>
      )}
    </ReactModal>
  )
}

export default ReportFormModal
