import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useModal } from 'react-modal-hook'
import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import ReportFormModal from '../../ReportFormModal'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleState } from '../../../context/locale'
import { useProjectedProductionData } from '../ProjectedProduction/hooks/useProjectedProductionData'
import { useTotalOwnershipData } from '../TotalOwnership/hooks/useTotalOwnershipData'

const CalculatorFooter = ({ activeSystem, activeYear }) => {
  const { multiply } = useCalculator()
  const { watch } = useFormContext()
  const { activeCurrency, activeLanguage } = useLocaleState()
  const { t } = useTranslation()
  const {
    average5dMonthlySavings,
    averageFlexMonthlySavings,
  } = useProjectedProductionData()
  const {
    calculateProfit,
    scannerSleevesTotal,
    totalCostOfOwnership,
  } = useTotalOwnershipData({ activeSystem })

  const formValues = watch([
    'restorative.volume',
    'restorative.volumeIncrease',
    'orthodontics.volume',
    'orthodontics.volumeIncrease',
    'invisalign.volumeIncrease',
    'caries.volume',
    'caries.volumeIncrease',
    'scannerSleeves.volume',
    'systemService.cost',
  ])

  const totalOwnershipData = {
    sections: [
      {
        title: 'ownership:sections.yearly.title',
        years: activeYear,
        rows: [
          {
            className: 'justify-between',
            label: 'ownership:table.rows.service.label',
            fields: [
              {
                name: formValues['systemService.cost'] || 0,
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            className: 'justify-between',
            label: 'ownership:table.rows.sleeves.label',
            sub: 'ownership:table.rows.sleeves.sub',
            fields: [
              {
                name: scannerSleevesTotal,
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            className: 'justify-end',
            label: 'ownership:table.rows.total.label',
            fields: [
              {
                className: 'text-port-gore md:text-3xl',
                name: totalCostOfOwnership,
                readOnly: true,
                type: 'currency',
              },
            ],
          },
        ],
      },
    ],
  }

  const financeFiguresData = {
    elementflex: {
      columns: [
        {
          label: 'ownership:grid.columns.payback.label',
          value: Math.round(
            totalCostOfOwnership / averageFlexMonthlySavings || 0
          ),
        },
        {
          label: 'ownership:grid.columns.profit.label',
          value: calculateProfit(activeYear),
          type: 'currency',
        },
        {
          label: 'ownership:grid.columns.roi.label',
          value: Math.round(
            multiply([calculateProfit(activeYear) / totalCostOfOwnership, 100])
          ),
          type: 'percent',
        },
      ],
    },
    element5d: {
      columns: [
        {
          label: 'ownership:grid.columns.payback.label',
          value: Math.round(
            totalCostOfOwnership / average5dMonthlySavings || 0
          ),
        },
        {
          label: 'ownership:grid.columns.profit.label',
          value: calculateProfit(activeYear),
          type: 'currency',
        },
        {
          label: 'ownership:grid.columns.roi.label',
          value: Math.round(
            multiply([calculateProfit(activeYear) / totalCostOfOwnership, 100])
          ),
          type: 'percent',
        },
      ],
    },
  }

  const submitForm = async (values) =>
    await fetch(`/print-pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        data: {
          calculator: {
            totalOwnershipData,
            financeFiguresData: financeFiguresData[activeSystem],
          },
          ...values,
        },
        currency: activeCurrency.value,
        language: activeLanguage.value,
      }),
    })

  const [showModal, hideModal] = useModal(
    () => (
      <ReportFormModal onRequestClose={hideModal} submitForm={submitForm} />
    ),
    [activeCurrency, activeLanguage, activeSystem, activeYear, formValues]
  )

  return (
    <div className="mb-8">
      <Button onClick={showModal}>{t('button.report')}</Button>
    </div>
  )
}

export default CalculatorFooter
