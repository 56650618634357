import React from 'react'

import SectionHeader from '../../../SectionHeader'
import SectionRow from '../../../SectionRow'

const ImpressionCost = ({ headers, rows, subtitle, title }) => {
  return (
    <form className="flex flex-col mb-8">
      {headers && (
        <SectionHeader headers={headers} title={{ subtitle, title }} />
      )}
      {rows && rows.map((row, index) => <SectionRow {...row} key={index} />)}
    </form>
  )
}

export default ImpressionCost
