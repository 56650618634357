import React from 'react'
import { array, bool, shape, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

const SectionHeader = ({
  children,
  headers: { fields, fill },
  size = 'regular',
  title,
}) => {
  const { t } = useTranslation()

  const cellClass = (size) => {
    switch (size) {
      case 'large':
        return 'space-y-2 w-1/2'
      case 'regular':
      default:
        return 'space-y-1 w-1/3'
    }
  }

  const labelClass = (size) => {
    switch (size) {
      case 'large':
        return 'md:text-xl text-lg'
      case 'regular':
      default:
        return 'md:text-base text-sm'
    }
  }

  return (
    <div
      className={cx(
        'bg-gray-nurse flex flex-col flex-1 items-end md:flex-row mb-2 py-4',
        {
          'md:bg-transparent ': !fill,
          'md:justify-end': !title,
        }
      )}
    >
      {title && (
        <div className="md:block px-2 md:text-right md:w-2/6 w-full">
          <h3 className="font-semibold text-xl text-turqoise">{title.title}</h3>
          {title.subtitle && <p className="italic">{title.subtitle}</p>}
        </div>
      )}
      <div className="flex items-end md:w-4/6 mt-2 md:mt-0 w-full">
        {fields
          ? fields.map(({ className, label, labels }, index) => {
              return (
                <div className={cx('px-2', cellClass(size))} key={index}>
                  {label ? (
                    <p
                      className={cx(
                        'font-semibold text-center md:text-right',
                        className,
                        labelClass(size)
                      )}
                    >
                      {t(label)}
                    </p>
                  ) : null}
                  {labels?.length
                    ? labels.map(({ label, sub }, index) => (
                        <div key={index}>
                          <p
                            className={cx(
                              'font-semibold text-center md:text-right',
                              className,
                              labelClass(size)
                            )}
                          >
                            {t(label)}
                          </p>
                          {sub ? <p className="text-sm">{t(sub)}</p> : null}
                        </div>
                      ))
                    : null}
                </div>
              )
            })
          : children}
      </div>
    </div>
  )
}

SectionHeader.propTypes = {
  headers: shape({
    fields: array,
    fill: bool,
  }),
  title: shape({
    title: string.isRequired,
    subtitle: string,
  }),
}

export default SectionHeader
