import React from 'react'
import { useTranslation } from 'react-i18next'

import SummaryCircle from '../../SummaryCircle'
import SectionTitle from '../../SectionTitle'
import TotalSection from '../../TotalSection'
import { useLocaleDispatch, useLocaleState } from '../../../context/locale'
import { useTotalCostOwnershipData } from './hooks/useTotalCostOwnershipData'
import YearSection from './YearSection'

const TotalCostOwnership = () => {
  const { currencyFormat } = useLocaleDispatch()
  const { activeCurrency } = useLocaleState()
  const {
    totalScannerSleeves,
    year1Savings,
    year1Total,
    year5Total,
    payback,
    profit,
    roi,
  } = useTotalCostOwnershipData()
  const { t } = useTranslation('ownership')

  const oneYearOwnershipData = {
    title: t('sections.yearly.title', { count: 1 }),
    rows: [
      {
        label: t('sections.yearly.table.rows.sleeves.label'),
        sub: t('sections.yearly.table.rows.sleeves.sub', { count: 1 }),
        fields: [
          {
            name: totalScannerSleeves(1),
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('sections.yearly.table.rows.system.label'),
        fields: [
          {
            name: `scanner.${activeCurrency.value}.oneYear`,
          },
        ],
      },
      {
        label: t('sections.yearly.table.rows.subscription.label', {
          count: 1,
        }),
        fields: [
          {
            name: 'Included',
            readOnly: true,
            type: 'text',
          },
        ],
      },
      {
        label: t('sections.yearly.table.rows.ownership.label', { count: 1 }),
        fields: [
          {
            className: 'text-port-gore md:text-3xl',
            name: year1Total,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  const fiveYearOwnershipData = {
    title: t('sections.yearly.title', { count: 5 }),
    rows: [
      {
        label: t('sections.yearly.table.rows.sleeves.label'),
        sub: t('sections.yearly.table.rows.sleeves.sub', { count: 5 }),
        fields: [
          {
            name: totalScannerSleeves(5),
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('sections.yearly.table.rows.system.label'),
        fields: [
          {
            name: `scanner.${activeCurrency.value}.fiveYear`,
          },
        ],
      },
      {
        label: t('sections.yearly.table.rows.subscription.label', {
          count: 5,
        }),
        fields: [
          {
            name: `service.${activeCurrency.value}.fiveYear`,
          },
        ],
      },
      {
        label: t('sections.yearly.table.rows.ownership.label', { count: 5 }),
        fields: [
          {
            className: 'text-port-gore md:text-3xl',
            name: year5Total,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  const financeFiguresData = {
    columns: [
      {
        label: t('sections.totals.payback'),
        value: isNaN(Math.ceil(payback)) ? `-` : Math.ceil(payback),
      },
      {
        label: t('sections.totals.profit'),
        value: profit || 0,
        type: 'currency',
      },
      {
        label: t('sections.totals.roi'),
        value: isNaN(roi) ? `-` : roi,
        type: 'percent',
      },
    ],
  }

  const summaryCircles = [
    {
      label: t('summary.year.label'),
      value: currencyFormat({ value: year1Savings() }),
    },
    {
      label: t('summary.month.label'),
      value: currencyFormat({ value: Math.ceil(year1Savings() / 12) }),
    },
  ]

  return (
    <main>
      <div className="lg:flex flex-1 items-center justify-between">
        <div className="w-full lg:w-3/4">
          <SectionTitle step={4} title={t('title')} theme="transparent" />
        </div>
      </div>
      <div className="lg:-mx-6 lg:flex">
        <div className="lg:w-4/6 lg:px-6 mb-8 lg:mb-0 w-full">
          <YearSection {...oneYearOwnershipData} />
          <YearSection {...fiveYearOwnershipData} />
          <TotalSection columns={financeFiguresData.columns} />
        </div>
        <div className="lg:w-2/6 lg:px-6 w-full">
          <div className="px-4 md:px-0 md:mb-4">
            {summaryCircles &&
              summaryCircles.map((summary, index) => (
                <SummaryCircle {...summary} className="mb-4" key={index} />
              ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default TotalCostOwnership
