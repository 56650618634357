import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'

import Button from '../../Button'
import { ReactComponent as Invisalign } from '../../../svg/invisalign.svg'
import ReportFormModal from '../../ReportFormModal'
import { useCalculatorDispatch } from '../../../context/calculator'
import { useTotalCostOwnershipData } from '../TotalCostOwnership/hooks/useTotalCostOwnershipData'
import { useLocaleState } from '../../../context/locale'

const Footer = ({ routes }) => {
  const { updateState } = useCalculatorDispatch()
  const { handleSubmit, watch } = useFormContext()
  const { activeCurrency, activeLanguage } = useLocaleState()
  const history = useHistory()
  const location = useLocation()
  const {
    totalScannerSleeves,
    payback,
    profit,
    roi,
    year1Total,
    year5Total,
  } = useTotalCostOwnershipData()
  const { t } = useTranslation(['ownership', 'translation'])

  const formValues = watch([
    'scanner.cad.oneYear',
    'scanner.usd.oneYear',
    'scanner.cad.fiveYear',
    'scanner.usd.fiveYear',
    'service.cad.fiveYear',
    'service.usd.fiveYear',
  ])

  const totalOwnershipData = {
    sections: [
      {
        title: t('ownership:sections.yearly.title', { count: 1 }),
        rows: [
          {
            label: t('ownership:sections.yearly.table.rows.sleeves.label'),
            sub: t('ownership:sections.yearly.table.rows.sleeves.sub', {
              count: 1,
            }),
            fields: [
              {
                name: totalScannerSleeves(1),
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            label: t('ownership:sections.yearly.table.rows.system.label'),
            fields: [
              {
                name: formValues[`scanner.${activeCurrency.value}.oneYear`],
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            label: t(
              'ownership:sections.yearly.table.rows.subscription.label',
              {
                count: 1,
              }
            ),
            fields: [
              {
                name: 'Included',
                readOnly: true,
                type: 'text',
              },
            ],
          },
          {
            label: t('ownership:sections.yearly.table.rows.ownership.label', {
              count: 1,
            }),
            fields: [
              {
                className: 'text-port-gore md:text-3xl',
                name: year1Total,
                readOnly: true,
                type: 'currency',
              },
            ],
          },
        ],
      },
      {
        title: t('ownership:sections.yearly.title', { count: 5 }),
        rows: [
          {
            label: t('ownership:sections.yearly.table.rows.sleeves.label'),
            sub: t('ownership:sections.yearly.table.rows.sleeves.sub', {
              count: 5,
            }),
            fields: [
              {
                name: totalScannerSleeves(5),
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            label: t('ownership:sections.yearly.table.rows.system.label'),
            fields: [
              {
                name: formValues[`scanner.${activeCurrency.value}.fiveYear`],
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            label: t(
              'ownership:sections.yearly.table.rows.subscription.label',
              {
                count: 5,
              }
            ),
            fields: [
              {
                name: formValues[`service.${activeCurrency.value}.fiveYear`],
                readOnly: true,
                type: 'currency',
              },
            ],
          },
          {
            label: t('ownership:sections.yearly.table.rows.ownership.label', {
              count: 5,
            }),
            fields: [
              {
                className: 'text-port-gore md:text-3xl',
                name: year5Total,
                readOnly: true,
                type: 'currency',
              },
            ],
          },
        ],
      },
    ],
  }

  const financeFiguresData = {
    columns: [
      {
        label: t('ownership:sections.totals.payback'),
        value: isNaN(Math.ceil(payback)) ? `-` : Math.ceil(payback),
      },
      {
        label: t('ownership:sections.totals.profit'),
        value: profit || 0,
        type: 'currency',
      },
      {
        label: t('ownership:sections.totals.roi'),
        value: isNaN(roi) ? `-` : roi,
        type: 'percent',
      },
    ],
  }

  const [showModal, hideModal] = useModal(
    () => (
      <ReportFormModal onRequestClose={hideModal} submitForm={submitForm} />
    ),
    [activeCurrency, activeLanguage, formValues]
  )

  const [footerOffset, setFooterOffset] = useState(0)

  const handleScroll = () => {
    const TARGET_OFFSET = 42
    const scrollHeight = document.documentElement.scrollHeight
    const scrollTop = window.scrollY
    const clientHeight = document.documentElement.clientHeight

    if (scrollHeight - scrollTop - clientHeight <= TARGET_OFFSET) {
      setFooterOffset(TARGET_OFFSET)
    } else {
      setFooterOffset(0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const submitForm = async (values) => {
    try {
      await fetch(`/print-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
          data: {
            calculator: {
              totalOwnershipData,
              financeFiguresData,
            },
            ...values,
          },
          currency: activeCurrency.value,
          language: activeLanguage.value,
        }),
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit = ({ data, route }) => {
    updateState(data)

    history.push(route)
  }

  const currentRoute = routes.find((route) => route.path === location.pathname)
  const currentRouteIndex = routes.indexOf(currentRoute)
  const routesLength = routes.length

  const isFirst = currentRouteIndex === 0
  const isLast = currentRouteIndex + 1 === routesLength

  const prevRoute = routes[currentRouteIndex - 1]
  const nextRoute = routes[currentRouteIndex + 1]

  return (
    <footer>
      <div className="-mx-8 my-8 md:flex md:items-start">
        <div className="px-8">
          <p>{t('translation:footer.terms')}</p>
        </div>
        <div className="mt-8 md:mt-0 px-8">
          <Invisalign className="w-64" />
        </div>
      </div>
      <nav
        className="-mx-6 bg-gray-nurse bottom-0 fixed max-w-screen-xl px-6 py-4 w-full"
        style={{
          bottom: `${footerOffset}px`,
          transition: 'bottom 200ms ease',
        }}
      >
        <div className="-mx-4 flex justify-end">
          {!isFirst && (
            <div className="px-4">
              <Button
                onClick={handleSubmit((data) =>
                  onSubmit({ data, route: prevRoute.path })
                )}
                theme="transparent"
              >
                &#x3c; Back
              </Button>
            </div>
          )}
          <div className="px-4">
            {isLast ? (
              <Button onClick={showModal}>Get report &#x3e;</Button>
            ) : (
              <Button
                onClick={handleSubmit((data) =>
                  onSubmit({ data, route: nextRoute.path })
                )}
              >
                Continue &#x3e;
              </Button>
            )}
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default Footer
