import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SectionHeader from '../../SectionHeader'
import SectionRow from '../../SectionRow'
import SectionTitle from '../../SectionTitle'
import SummaryCircle from '../../SummaryCircle'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleDispatch } from '../../../context/locale'

const ProjectedIncrease = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()
  const { currencyFormat } = useLocaleDispatch()
  const { t } = useTranslation(['production', 'treatments'])

  const formValues = watch([
    'crown.volume',
    'crown.volumeIncrease',
    'crown.revenueIncrease',
    'implants.volume',
    'implants.volumeIncrease',
    'implants.revenueIncrease',
    'night.volume',
    'night.volumeIncrease',
    'night.revenueIncrease',
    'veneers.volume',
    'veneers.volumeIncrease',
    'veneers.revenueIncrease',
    'orthodontics.volume',
    'orthodontics.volumeIncrease',
    'orthodontics.revenueIncrease',
  ])

  const crownIncrease = multiply([
    formValues['crown.volumeIncrease'],
    formValues['crown.revenueIncrease'],
  ])

  const implantsIncrease = multiply([
    formValues['implants.volumeIncrease'],
    formValues['implants.revenueIncrease'],
  ])

  const nightIncrease = multiply([
    formValues['night.volumeIncrease'],
    formValues['night.revenueIncrease'],
  ])

  const veneersIncrease = multiply([
    formValues['veneers.volumeIncrease'],
    formValues['veneers.revenueIncrease'],
  ])

  const orthodonticsIncrease = multiply([
    formValues['orthodontics.volumeIncrease'],
    formValues['orthodontics.revenueIncrease'],
  ])

  const totalIncrease = sum([
    crownIncrease,
    implantsIncrease,
    nightIncrease,
    veneersIncrease,
    orthodonticsIncrease,
  ])

  const projectedIncreaseData = {
    headers: {
      fields: [
        {
          label: t('production:sections.volume.table.header.volume.label'),
        },
        {
          label: t('production:sections.volume.table.header.revenue.label'),
        },
        {
          label: t('production:sections.volume.table.header.change.label'),
        },
      ],
    },
    rows: [
      {
        label: t('treatments:table.rows.crown.label'),
        fields: [
          {
            name: 'crown.volumeIncrease',
          },
          {
            name: 'crown.revenueIncrease',
          },
          {
            name: Math.floor(
              multiply([
                [formValues['crown.volumeIncrease']] /
                  formValues['crown.volume'],
                100,
              ])
            ),
            readOnly: true,
            type: 'percent',
          },
        ],
      },
      {
        label: t('treatments:table.rows.implants.label'),
        fields: [
          {
            name: 'implants.volumeIncrease',
          },
          {
            name: 'implants.revenueIncrease',
          },
          {
            name: Math.floor(
              multiply([
                [formValues['implants.volumeIncrease']] /
                  formValues['implants.volume'],
                100,
              ])
            ),
            readOnly: true,
            type: 'percent',
          },
        ],
      },
      {
        label: t('treatments:table.rows.night.label'),
        fields: [
          {
            name: 'night.volumeIncrease',
          },
          {
            name: 'night.revenueIncrease',
          },
          {
            name:
              Math.floor(
                multiply([
                  [formValues['night.volumeIncrease']] /
                    formValues['night.volume'],
                  100,
                ])
              ) || 0,
            readOnly: true,
            type: 'percent',
          },
        ],
      },
      {
        label: t('treatments:table.rows.veneers.label'),
        fields: [
          {
            name: 'veneers.volumeIncrease',
          },
          {
            name: 'veneers.revenueIncrease',
          },
          {
            name: Math.floor(
              multiply([
                [formValues['veneers.volumeIncrease']] /
                  formValues['veneers.volume'],
                100,
              ])
            ),
            readOnly: true,
            type: 'percent',
          },
        ],
      },
      {
        label: t('treatments:table.rows.orthodontics.label'),
        fields: [
          {
            name: 'orthodontics.volumeIncrease',
          },
          {
            name: 'orthodontics.revenueIncrease',
          },
          {
            name: Math.floor(
              multiply([
                [formValues['orthodontics.volumeIncrease']] /
                  formValues['orthodontics.volume'],
                100,
              ])
            ),
            readOnly: true,
            type: 'percent',
          },
        ],
      },
    ],
  }

  const summaryCircles = [
    {
      label: t('production:summary.projected.title'),
      value: currencyFormat({ value: totalIncrease }),
    },
    {
      label: t('production:summary.average.title'),
      value: currencyFormat({ value: Math.floor(totalIncrease / 12) }),
    },
  ]

  return (
    <main>
      <div className="lg:flex flex-1 items-center justify-between">
        <div className="w-full lg:w-3/4">
          <SectionTitle step={3} title={t('title')} theme="transparent" />
        </div>
      </div>
      <div className="lg:-mx-6 lg:flex">
        <div className="lg:w-4/6 lg:px-6 mb-8 lg:mb-0 w-full">
          <form className="flex flex-col lg:border-t lg:pt-4">
            {projectedIncreaseData.headers && (
              <SectionHeader headers={projectedIncreaseData.headers} />
            )}
            {projectedIncreaseData.rows &&
              projectedIncreaseData.rows.map((row, index) => (
                <SectionRow {...row} key={index} />
              ))}
          </form>
        </div>
        <div className="lg:w-2/6 lg:px-6 w-full">
          <div className="px-4 md:px-0 md:mb-4">
            {summaryCircles &&
              summaryCircles.map((summary, index) => (
                <SummaryCircle {...summary} className="mb-4" key={index} />
              ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProjectedIncrease
