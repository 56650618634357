import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import TagManager from 'react-gtm-module'

import Disclaimers from '../Disclaimers'
import DentalTreatments from '../DentalTreatments'
import Header from '../../Header'
import LiftStudy from '../LiftStudy'
import ProjectedProduction from '../ProjectedProduction'
import ProjectedSavings from '../ProjectedSavings'
import TotalOwnership from '../TotalOwnership'
import { gtmTags } from '../../gtmTags'

const defaultValues = {
  restorative: {
    fee: 0,
    volume: 0,
    volumeIncrease: 0,
  },
  orthodontics: {
    fee: 0,
    volume: 0,
    volumeIncrease: 0,
  },
  invisalign: {
    fee: 0,
    volume: 0,
    volumeIncrease: 0,
  },
  caries: {
    fee: 0,
    volume: 0,
    volumeIncrease: 0,
  },
  alginate: {
    perUnit: 0,
    volume: 0,
  },
  silicone: {
    perUnit: 0,
    volume: 0,
  },
  models: {
    perUnit: 0,
    volume: 0,
    editable: true,
  },
  storage: {
    costs: 0,
  },
  time: {
    perUnit: 0,
  },
  remakes: {
    volume: 0,
  },
  hourlyRate: {
    perUnit: 0,
  },
  scannerSleeves: {
    volume: 0,
  },
  systemService: {
    cost: 0,
  },
}

const EUCalculator = () => {
  useEffect(() => {
    const regionCode = window.location.pathname.slice(1)

    if (regionCode in gtmTags) {
      TagManager.initialize({
        gtmId: gtmTags[regionCode],
      })
    }
  }, [])

  const methods = useForm({
    defaultValues,
  })

  return (
    <FormContext {...methods}>
      <Header resetForm={() => methods.reset(defaultValues)} />
      <div className="max-w-screen-xl mx-auto px-4 py-8 md:px-6 md:py-12">
        <DentalTreatments />
        <ProjectedSavings />
        <LiftStudy />
        <ProjectedProduction />
        <TotalOwnership />
        <Disclaimers />
      </div>
    </FormContext>
  )
}

export default EUCalculator
