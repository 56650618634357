import React from 'react'

import SectionHeader from '../../../SectionHeader'
import SectionRow from '../../../SectionRow'

const TotalProduction = ({ headers, rows, size }) => {
  return (
    <form className="flex flex-col">
      {headers && <SectionHeader headers={headers} size={size} />}
      {rows &&
        rows.map((row, index) => (
          <SectionRow {...row} size={size} key={index} />
        ))}
    </form>
  )
}

export default TotalProduction
