import React from 'react'
import { ModalProvider } from 'react-modal-hook'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import EUCalculator from '../EU/Calculator'
import { LocaleProvider } from '../../context/locale'
import NACalculator from '../NA/Calculator'
import RegionSelect from '../RegionSelect'
import Report from '../Report'

const Index = () => {
  return (
    <React.Fragment>
      <Router>
        <LocaleProvider>
          <ModalProvider>
            <Switch>
              <Route path="/" exact render={() => <RegionSelect />} />
              <Route
                path="/na"
                render={(props) => <NACalculator {...props} />}
              />
              <Route
                path="/eu"
                render={(props) => <EUCalculator {...props} />}
              />

              <Route
                path="/latam"
                render={(props) => <EUCalculator {...props} />}
              />

              <Route path="/report" render={(props) => <Report {...props} />} />
            </Switch>
          </ModalProvider>
        </LocaleProvider>
      </Router>
    </React.Fragment>
  )
}

export default Index
