import React from 'react'
import { useLocation } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import Header from '../Header'
import SectionTitle from '../SectionTitle'
import YearSection from '../NA/TotalCostOwnership/YearSection'
import TotalSection from '../TotalSection'

const Report = () => {
  const methods = useForm()
  const { search } = useLocation()
  const { t } = useTranslation('ownership')
  const { data } = queryString.parse(search)

  const { totalOwnershipData, financeFiguresData } = JSON.parse(atob(data))

  return (
    <React.Fragment>
      <Header />
      <main>
        <div className="lg:flex flex-1 items-center justify-between">
          <div className="w-full lg:w-3/4">
            <SectionTitle step={4} title={t('title')} theme="transparent" />
          </div>
        </div>
        <div className="px-6">
          <FormContext {...methods}>
            {totalOwnershipData.sections.map((section, index) => (
              <YearSection {...section} key={index} />
            ))}
            <TotalSection columns={financeFiguresData.columns} />
          </FormContext>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Report
