import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AdditionalCost from './AdditionalCost'
import ImpressionCost from './ImpressionCost'
import SectionTitle from '../../SectionTitle'
import SummaryCircle from '../../SummaryCircle'
import TimeSavings from './TimeSavings'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleDispatch } from '../../../context/locale'
import { useProjectedSavingsData } from './hooks/useProjectedSavingsData'

const ProjectedSavings = () => {
  const { multiply } = useCalculator()
  const { watch } = useFormContext()
  const { currencyFormat } = useLocaleDispatch()
  const {
    annualImpressionsVolume,
    annualImpressionsHours,
    totalCosts,
    totalImpressionsCosts,
    totalProjectedAnnualSavings,
    timeSaved,
  } = useProjectedSavingsData()
  const { t } = useTranslation('savings')

  const formValues = watch([
    'alginate.perUnit',
    'alginate.volume',
    'silicone.perUnit',
    'silicone.volume',
    'storage.costs',
    'models.perUnit',
    'models.volume',
    'models.editable',
    'time.perUnit',
    'remakes.volume',
    'hourlyRate.perUnit',
  ])

  const impressionCostData = {
    title: t('sections.impression.title'),
    subtitle: t('sections.impression.subtitle'),
    headers: {
      fill: true,
      fields: [
        {
          label: t('sections.impression.table.header.perUnit.label'),
        },
        {
          label: t('sections.impression.table.header.volume.label'),
        },
        {
          label: t('sections.impression.table.header.costs.label'),
        },
      ],
    },
    rows: [
      {
        label: t('sections.impression.table.rows.alginate.label'),
        fields: [
          {
            name: 'alginate.perUnit',
          },
          {
            name: 'alginate.volume',
          },
          {
            name: multiply([
              formValues['alginate.perUnit'],
              formValues['alginate.volume'],
            ]),
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('sections.impression.table.rows.silicone.label'),
        fields: [
          {
            name: 'silicone.perUnit',
          },
          {
            name: 'silicone.volume',
          },
          {
            name: multiply([
              formValues['silicone.perUnit'],
              formValues['silicone.volume'],
            ]),
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  const additionalCostData = {
    title: t('sections.additional.title'),
    headers: {
      fill: true,
    },
    rows: [
      {
        className: 'justify-end',
        label: t('sections.additional.table.rows.storage.label'),
        fields: [
          {
            name: 'storage.costs',
          },
        ],
      },
      {
        label: t('sections.additional.table.rows.models.label'),
        fields: [
          {
            name: 'models.perUnit',
          },
          {
            name: formValues['models.editable']
              ? 'models.volume'
              : annualImpressionsVolume,
            readOnly: !formValues['models.editable'],
          },
          {
            name: multiply([
              formValues['models.perUnit'],
              formValues['models.editable']
                ? formValues['models.volume']
                : annualImpressionsVolume,
            ]),
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        className: 'justify-end',
        label: t('sections.additional.table.rows.total.label'),
        fields: [
          {
            name: totalCosts,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  const timeSavingsData = {
    title: t('sections.time.title'),
    headers: {
      fill: true,
      fields: [
        {
          label: t('sections.time.table.header.average.label'),
        },
        {
          label: t('sections.time.table.header.volume.label'),
        },
        {
          label: t('sections.time.table.header.total.label'),
        },
      ],
    },
    rows: [
      {
        label: t('sections.time.table.rows.time.label'),
        sub: t('sections.time.table.rows.time.sub'),
        fields: [
          {
            name: 'time.perUnit',
          },
          {
            name: annualImpressionsVolume,
            readOnly: true,
          },
          {
            name: multiply([
              formValues['time.perUnit'],
              annualImpressionsVolume,
            ]),
            readOnly: true,
          },
        ],
      },
      {
        className: 'justify-end',
        label: t('sections.time.table.rows.remakes.label'),
        sub: t('sections.time.table.rows.remakes.sub'),
        fields: [
          {
            name: 'remakes.volume',
          },
          {
            name: multiply([
              formValues['time.perUnit'],
              formValues['remakes.volume'],
            ]),
            readOnly: true,
          },
        ],
      },
      {
        className: 'justify-end',
        label: t('sections.time.table.rows.hours.label'),
        fields: [
          {
            name: annualImpressionsHours,
            readOnly: true,
          },
        ],
      },
      {
        label: t('sections.time.table.rows.hourlyRate.label'),
        fields: [
          {
            name: 'hourlyRate.perUnit',
          },
          {
            name: multiply([
              annualImpressionsHours,
              formValues['hourlyRate.perUnit'],
            ]),
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        className: 'justify-end',
        label: t('sections.time.table.rows.totalCosts.label'),
        sub: t('sections.time.table.rows.totalCosts.sub'),
        fields: [
          {
            className: 'text-port-gore md:text-3xl',
            name: totalImpressionsCosts,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  const summaryCircles = [
    {
      icon: 'calendar',
      label: t('summary.savings.label'),
      value: currencyFormat({ value: totalProjectedAnnualSavings }),
    },
    {
      icon: 'piggy',
      label: t('summary.average.label'),
      value: currencyFormat({ value: totalProjectedAnnualSavings / 12 }),
    },
    {
      icon: 'clock',
      label: t('summary.time.label'),
      note: t('summary.time.note', { interpolation: { escapeValue: true } }),
      value: Math.round(timeSaved),
    },
  ]

  return (
    <section className="mb-8 md:-mx-6 md:flex">
      <div className="md:w-4/6 md:px-6 mb-8 md:mb-0 w-full">
        <SectionTitle theme="violet" step={2} title={t('title')} />
        <ImpressionCost {...impressionCostData} />
        <AdditionalCost {...additionalCostData} />
        <TimeSavings {...timeSavingsData} />
      </div>
      <div className="md:w-2/6 md:px-6 w-full">
        <div className="px-4 md:px-0 md:mb-4">
          {summaryCircles &&
            summaryCircles.map((summary, index) => (
              <SummaryCircle
                {...summary}
                theme="violet"
                className="mb-4"
                key={index}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export default ProjectedSavings
