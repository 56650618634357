import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SectionHeader from '../../SectionHeader'
import SectionRow from '../../SectionRow'
import SectionTitle from '../../SectionTitle'
import SummaryCircle from '../../SummaryCircle'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleDispatch } from '../../../context/locale'

const ProjectedSavings = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()
  const { currencyFormat } = useLocaleDispatch()
  const { t } = useTranslation(['savings', 'treatments'])

  const formValues = watch([
    'crown.perUnit',
    'crown.volume',
    'implants.perUnit',
    'implants.volume',
    'night.perUnit',
    'night.volume',
    'veneers.perUnit',
    'veneers.volume',
    'orthodontics.perUnit',
    'orthodontics.volume',
    'remakes.perUnit',
    'remakes.volume',
    'storage.perUnit',
  ])

  const crownSavings = multiply([
    formValues['crown.perUnit'],
    formValues['crown.volume'],
  ])

  const implantsSavings = multiply([
    formValues['implants.perUnit'],
    formValues['implants.volume'],
  ])

  const nightSavings = multiply([
    formValues['night.perUnit'],
    formValues['night.volume'],
  ])

  const veneersSavings = multiply([
    formValues['veneers.perUnit'],
    formValues['veneers.volume'],
  ])

  const orthodonticsSavings = multiply([
    formValues['orthodontics.perUnit'],
    formValues['orthodontics.volume'],
  ])

  const remakesSavings = multiply([
    formValues['remakes.perUnit'],
    formValues['remakes.volume'],
  ])

  const totalSavings = sum([
    crownSavings,
    implantsSavings,
    nightSavings,
    veneersSavings,
    orthodonticsSavings,
    remakesSavings,
    formValues['storage.perUnit'],
  ])

  const projectedSavingsData = {
    headers: {
      fields: [
        {
          label: t('savings:table.header.cost'),
        },
        {
          label: t('savings:table.header.volume'),
        },
        {
          label: t('savings:table.header.savings'),
        },
      ],
    },
    rows: [
      {
        label: t('treatments:table.rows.crown.label'),
        fields: [
          {
            name: 'crown.perUnit',
          },
          {
            name: formValues['crown.volume'],
            readOnly: true,
          },
          {
            name: crownSavings,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('treatments:table.rows.implants.label'),
        fields: [
          {
            name: 'implants.perUnit',
          },
          {
            name: formValues['implants.volume'],
            readOnly: true,
          },
          {
            name: implantsSavings,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('treatments:table.rows.night.label'),
        fields: [
          {
            name: 'night.perUnit',
          },
          {
            name: formValues['night.volume'],
            readOnly: true,
          },
          {
            name: nightSavings,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('treatments:table.rows.veneers.label'),
        fields: [
          {
            name: 'veneers.perUnit',
          },
          {
            name: formValues['veneers.volume'],
            readOnly: true,
          },
          {
            name: veneersSavings,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('treatments:table.rows.orthodontics.label'),
        fields: [
          {
            name: 'orthodontics.perUnit',
          },
          {
            name: formValues['orthodontics.volume'],
            readOnly: true,
          },
          {
            name: orthodonticsSavings,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('savings:table.rows.remakes.label'),
        fields: [
          {
            name: 'remakes.perUnit',
          },
          {
            name: formValues['remakes.volume'],
            readOnly: true,
          },
          {
            name: remakesSavings,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('savings:table.rows.storage.label'),
        fields: [
          {
            name: 'storage.perUnit',
          },
        ],
      },
    ],
  }

  const summaryCircles = [
    {
      label: t('savings:summary.savings.label'),
      value: currencyFormat({ value: totalSavings }),
    },
    {
      label: t('savings:summary.average.label'),
      value: currencyFormat({ value: Math.floor(totalSavings / 12) }),
    },
  ]

  return (
    <main>
      <div className="lg:flex flex-1 items-center justify-between">
        <div className="w-full lg:w-3/4">
          <SectionTitle
            step={2}
            title={t('savings:title')}
            subtitle={t('savings:subtitle')}
            theme="transparent"
          />
        </div>
      </div>
      <div className="lg:-mx-6 lg:flex">
        <div className="lg:w-4/6 lg:px-6 mb-8 lg:mb-0 w-full">
          <form className="flex flex-col lg:border-t lg:pt-4">
            {projectedSavingsData.headers && (
              <SectionHeader headers={projectedSavingsData.headers} />
            )}
            {projectedSavingsData.rows &&
              projectedSavingsData.rows.map((row, index) => (
                <SectionRow {...row} key={index} />
              ))}
          </form>
        </div>
        <div className="lg:w-2/6 lg:px-6 w-full">
          <div className="px-4 md:px-0 md:mb-4">
            {summaryCircles &&
              summaryCircles.map((summary, index) => (
                <SummaryCircle {...summary} className="mb-4" key={index} />
              ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProjectedSavings
