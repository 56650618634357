import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import { languages } from '../lib/languages'

const LocaleDispatchContext = createContext()
const LocaleStateContext = createContext()

const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'SWITCH_CURRENCY':
      return {
        ...state,
        activeCurrency: payload.activeCurrency,
      }
    case 'SWITCH_LANGUAGE':
      return {
        ...state,
        activeLanguage: payload.activeLanguage,
      }
    default:
      throw new Error(`Invalid action: ${type}`)
  }
}

const LocaleProvider = ({ children }) => {
  const { pathname, search } = useLocation()
  const { i18n, t } = useTranslation('products')
  const { currency, language } = queryString.parse(search)

  const [, activeRegionPath] = pathname.split('/')

  const defaultProducts = useMemo(
    () => [
      {
        label: t('products:flex.label'),
        name: 'Element Flex',
        value: 'elementflex',
      },
      {
        label: t('products:element2.label'),
        name: 'Element 2',
        value: 'element2',
      },
      {
        label: t('products:element2plus.label'),
        name: 'Element 2 Plus',
        value: 'element2plus',
      },
      {
        label: t('products:5d.label'),
        name: '5D',
        premium: true,
        value: 'element5d',
      },
      {
        label: t('products:5dplus.label'),
        name: '5D Plus',
        premium: true,
        value: 'element5dplus',
      },
      {
        label: t('products:5dpluslite.label'),
        name: '5D Plus Lite',
        value: 'element5dpluslite',
      },
    ],
    [t]
  )

  const naProducts = useMemo(
    () => [
      {
        label: t('products:flex.label'),
        name: 'Element Flex',
        value: 'elementflex',
      },
      {
        label: t('products:element2.label'),
        name: 'Element 2',
        value: 'element2',
      },
      {
        label: t('products:5dplus.label'),
        name: '5D Plus',
        premium: true,
        value: 'element5dplus',
      },
      {
        label: t('products:5dpluslite.label'),
        name: '5D Plus Lite',
        value: 'element5dpluslite',
      },
      {
        label: t('products:5dpluslaptop.label'),
        name: '5D Plus Laptop',
        value: 'element5dlaptop',
      },
      {
        label: t('products:5dplusmobile.label'),
        name: '5D Plus Mobile',
        premium: true,
        value: 'element5dmobile',
      },
    ],
    [t]
  )

  const latAmProducts = useMemo(
    () => [
      {
        label: t('products:element2.label'),
        name: 'Element™ 2',
        value: 'element2',
      },
      {
        label: t('products:5d.label'),
        name: '5D',
        premium: true,
        value: 'element5d',
      },
      {
        label: t('products:5dplus.label'),
        name: '5D Plus',
        premium: true,
        value: 'element5dplus',
      },
    ],
    [t]
  )

  const currencies = useMemo(
    () => [
      {
        regions: ['eu'],
        languages: ['en', 'fr', 'de', 'it', 'pl', 'es'],
        value: 'eur',
        label: 'EUR',
        sleeveFee: 2.96,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['en', 'fr', 'de', 'it'],
        value: 'chf',
        label: 'CHF',
        sleeveFee: 3.16,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['en'],
        value: 'gbp',
        label: 'GBP',
        sleeveFee: 2.48,
        availableProducts: defaultProducts,
      },

      {
        regions: ['na'],
        languages: ['en'],
        value: 'usd',
        label: 'USD',
        availableProducts: naProducts,
      },

      {
        regions: ['na'],
        languages: ['en'],
        value: 'cad',
        label: 'CAD',
        availableProducts: naProducts,
      },

      {
        regions: ['eu'],
        languages: ['ru'],
        value: 'rub',
        label: 'RUR',
        sleeveFee: 302,
        availableProducts: [
          {
            label: t('products:flex.label'),
            name: 'Element Flex',
            value: 'elementflex',
          },
          {
            label: t('products:element2.label'),
            name: 'Element 2',
            value: 'element2',
          },
        ],
      },

      {
        regions: ['eu'],
        languages: ['pl'],
        value: 'pln',
        label: 'PLN',
        sleeveFee: 13.04,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['en'],
        value: 'czk',
        label: 'CZK',
        sleeveFee: 77.2,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['en'],
        value: 'hrk',
        label: 'HRK',
        sleeveFee: 24,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['en'],
        value: 'huf',
        label: 'HUF',
        sleeveFee: 1008,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['en'],
        value: 'bgn',
        label: 'BGN',
        sleeveFee: 6,
        availableProducts: defaultProducts,
      },

      {
        regions: ['eu'],
        languages: ['fr'],
        value: 'mad',
        label: 'MAD',
        sleeveFee: 32.04,
        availableProducts: [
          {
            label: t('products:element2.label'),
            name: 'Element 2',
            value: 'element2',
          },
        ],
      },

      {
        regions: ['latam'],
        languages: ['pt'],
        value: 'brl',
        label: 'BRL',
        sleeveFee: 16.34,
        availableProducts: [
          {
            label: t('products:flex.label'),
            name: 'Element Flex',
            value: 'elementflex',
          },
          ...latAmProducts,
        ],
      },

      {
        regions: ['latam'],
        languages: ['es'],
        value: 'mxn',
        label: 'MXN',
        sleeveFee: 88,
        availableProducts: latAmProducts,
      },

      {
        regions: ['latam'],
        languages: ['en', 'es'],
        value: 'usd',
        label: 'USD',
        sleeveFee: 7,
        availableProducts: latAmProducts,
      },
    ],
    [defaultProducts, naProducts, latAmProducts, t]
  )

  const [state, dispatch] = useReducer(reducer, {
    activeCurrency: currencies.find((currency) =>
      activeRegionPath === 'eu'
        ? currency.value === 'eur'
        : activeRegionPath === 'na'
        ? currency.value === 'usd' && currency.regions.includes('na')
        : activeRegionPath === 'latam'
        ? currency.value === 'usd' && currency.regions.includes('latam')
        : currency.value === 'brl'
    ),
    activeLanguage: languages.find((language) => language.value === 'en'),
  })

  const availableCurrencies = currencies.filter(
    (currency) =>
      currency.regions.includes(activeRegionPath) &&
      currency.languages.includes(state.activeLanguage.value)
  )

  const availableLanguages = languages.filter((language) =>
    language.regions.includes(activeRegionPath)
  )

  const switchCurrency = useCallback(
    (currency) =>
      dispatch({
        payload: {
          activeCurrency: currencies.find(
            (curr) =>
              curr.value === currency && curr.regions.includes(activeRegionPath)
          ),
        },
        type: 'SWITCH_CURRENCY',
      }),
    [currencies, activeRegionPath]
  )

  const switchLanguage = useCallback(
    (language) => {
      dispatch({
        payload: {
          activeLanguage: languages.find(({ value }) => value === language),
        },
        type: 'SWITCH_LANGUAGE',
      })

      const { defaultCurrency } = languages.find(
        ({ value }) => value === language
      )

      dispatch({
        payload: {
          activeCurrency: currencies.find(
            ({ value }) => value === defaultCurrency
          ),
        },
        type: 'SWITCH_CURRENCY',
      })
    },
    [currencies]
  )

  useEffect(() => {
    if (!currency || currency === state.activeCurrency.value) return

    switchCurrency(currency)
  }, [currency, state, switchCurrency])

  useEffect(() => {
    if (!language || language === state.activeLanguage.value) return

    i18n.changeLanguage(language, () => switchLanguage(language))
  }, [i18n, language, state, switchLanguage])

  const currencyFormat = ({
    currency = state.activeCurrency.value,
    round = true,
    value,
  }) => {
    const pathName = window.location.pathname
    const maximumFractionDigits = round ? 0 : 2

    const formattedValue = value
      .toLocaleString('en-US', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: maximumFractionDigits,
        maximumFractionDigits,
      })
      .replaceAll(',', '.')

    if (currency === 'mxn') return `${currency.toUpperCase()} ${formattedValue}`
    if (pathName.includes('latam') && currency === 'usd')
      return `USD ${formattedValue}`

    return new Intl.NumberFormat(state.activeLanguage.locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits,
    }).format(value)
  }

  const numberFormat = (value) => new Intl.NumberFormat().format(value)

  const percentFormat = (value) =>
    `${new Intl.NumberFormat({ style: 'percent' }).format(value)}%`

  useEffect(() => {
    let currency

    if (activeRegionPath === 'eu') {
      currency = 'eur'
    } else if (activeRegionPath !== 'na') {
      currency = state.activeLanguage.value === 'pt' ? 'brl' : 'usd'
    } else {
      currency = 'usd'
    }

    switchCurrency(currency)
  }, [activeRegionPath, state.activeLanguage, switchCurrency])

  return (
    <LocaleStateContext.Provider
      value={{
        ...state,
        activeRegionPath,
        availableCurrencies,
        availableLanguages,
      }}
    >
      <LocaleDispatchContext.Provider
        value={{
          currencyFormat,
          numberFormat,
          percentFormat,
          switchCurrency,
          switchLanguage,
        }}
      >
        {children}
      </LocaleDispatchContext.Provider>
    </LocaleStateContext.Provider>
  )
}

const useLocaleDispatch = () => {
  const context = useContext(LocaleDispatchContext)

  if (!context)
    throw new Error('useLocaleDispatch must be used within a LocaleProvider')

  return context
}

const useLocaleState = () => {
  const context = useContext(LocaleStateContext)

  if (!context)
    throw new Error('useLocaleState must be used within a LocaleProvider')
  return context
}

export { LocaleProvider, useLocaleDispatch, useLocaleState }
