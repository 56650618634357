import React from 'react'
import { NavLink } from 'react-router-dom'

import Header from '../Header'

const RegionSelect = () => {
  const regions = [
    {
      label: 'Europe',
      path: '/eu',
    },
    {
      label: 'North America',
      path: '/na',
    },
    {
      label: 'Latam',
      path: '/latam',
    }
  ]

  return (
    <React.Fragment>
      <Header />
      <div className="max-w-screen-xl mx-auto px-4 py-8 md:px-6 md:py-12">
        <ul className="md:w-1/2 mx-auto">
          <li className="bg-port-gore border-b-2 rounded-t-lg px-4 py-4 md:py-8 text-xl md:text-3xl text-center text-white">
            Please select your region
          </li>
          {regions.map((region, index) => (
            <li
              key={index}
              className="bg-port-gore hover:bg-opacity-50 last:rounded-b-lg"
            >
              <NavLink
                className="block px-4 py-4 md:py-8 text-xl md:text-3xl text-center text-white hover:bg-affair"
                exact
                to={region.path}
              >
                {region.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default RegionSelect
