import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { ReactComponent as Dentist } from '../../../svg/dentist.svg'
import SectionTitle from '../../SectionTitle'
import { useLocaleDispatch, useLocaleState } from '../../../context/locale'

const LiftStudy = () => {
  const { currencyFormat, percentFormat } = useLocaleDispatch()
  const { activeCurrency, activeLanguage } = useLocaleState()
  const { t } = useTranslation('study')

  const isRussianCalculator = activeLanguage.value === 'ru'

  const showClaimsSection =
    !isRussianCalculator && !['mad'].includes(activeCurrency.value)

  const studyData = {
    sections: [
      {
        title: t('sections.study.title', {
          interpolation: { escapeValue: true },
        }),
        sub: t('sections.study.sub'),
        rows: [
          {
            className: 'md:grid grid-cols-2 gap-2 mb-6',
            title: t('sections.study.rows.gp.title'),
            sub: t('sections.study.rows.gp.sub'),
            figures: [
              {
                title: t('sections.study.rows.gp.figures.revenue.title'),
                type: 'currency',
                value: {
                  bgn: currencyFormat({
                    value: 135884,
                    currency: 'eur',
                  }),
                  chf: currencyFormat({
                    value: 154980,
                  }),
                  czk: currencyFormat({
                    value: 135884,
                    currency: 'eur',
                  }),
                  eur: currencyFormat({
                    value: 135884,
                  }),
                  gbp: currencyFormat({
                    value: 120372,
                  }),
                  hrk: currencyFormat({
                    value: 135884,
                    currency: 'eur',
                  }),
                  huf: currencyFormat({
                    value: 135884,
                    currency: 'eur',
                  }),
                  mad: currencyFormat({
                    value: 1460305,
                  }),
                  pln: currencyFormat({
                    value: 597890,
                  }),
                  rub: currencyFormat({
                    value: 135884,
                    currency: 'eur',
                  }),
                  brl: currencyFormat({
                    value: 236943,
                    currency: 'brl',
                  }),
                  mxn: currencyFormat({
                    value: 2681067,
                    currency: 'mxn',
                  }),
                  usd: currencyFormat({
                    value: 109650,
                    currency: 'usd',
                  }),
                },
                note: t('sections.study.rows.gp.figures.revenue.note'),
              },
            ],
          },
          {
            className: 'md:grid grid-cols-2 gap-2 mb-6',
            title: t('sections.study.rows.orthodontist.title'),
            sub: t('sections.study.rows.orthodontist.sub'),
            figures: [
              {
                title: t(
                  'sections.study.rows.orthodontist.figures.revenue.title'
                ),
                type: 'currency',
                value: {
                  bgn: currencyFormat({
                    value: 388240,
                    currency: 'eur',
                  }),
                  chf: currencyFormat({
                    value: 442800,
                  }),
                  czk: currencyFormat({
                    value: 388240,
                    currency: 'eur',
                  }),
                  eur: currencyFormat({
                    value: 388240,
                  }),
                  gbp: currencyFormat({
                    value: 343920,
                  }),
                  hrk: currencyFormat({
                    value: 388240,
                    currency: 'eur',
                  }),
                  huf: currencyFormat({
                    value: 388240,
                    currency: 'eur',
                  }),
                  mad: currencyFormat({
                    value: 4172299,
                  }),
                  pln: currencyFormat({
                    value: 1708256,
                  }),
                  rub: currencyFormat({
                    value: 388240,
                    currency: 'eur',
                  }),
                  brl: currencyFormat({
                    value: 594682,
                    currency: 'brl',
                  }),
                  mxn: currencyFormat({
                    value:  6728953,
                    currency: 'mxn',
                  }),
                  usd: currencyFormat({
                    value: 275200,
                    currency: 'usd',
                  }),
                },
                note: t(
                  'sections.study.rows.orthodontist.figures.revenue.note'
                ),
              },
            ],
          },
        ],
      },
      ...(showClaimsSection
        ? [
            {
              title: t('sections.claims.title', {
                interpolation: { escapeValue: true },
              }),
              rows: [
                {
                  title: t('sections.claims.rows.increase.title'),
                  figures: [
                    {
                      className: 'md:w-1/3',
                      title: t(
                        'sections.claims.rows.increase.figures.detection.title'
                      ),
                      type: 'percent',
                      value: (
                        <React.Fragment>
                          {percentFormat(56)}
                          &#179;
                        </React.Fragment>
                      ),
                    },
                    {
                      className: 'md:w-1/3',
                      title: t(
                        'sections.claims.rows.increase.figures.revenue.title'
                      ),
                      type: 'percent',
                      value: (
                        <React.Fragment>
                          {percentFormat(34)}
                          &#179;
                        </React.Fragment>
                      ),
                    },
                    {
                      className: 'md:w-1/3',
                      title: t(
                        'sections.claims.rows.increase.figures.acceptance.title'
                      ),
                      type: 'percent',
                      value: (
                        <React.Fragment>
                          {percentFormat(71)}
                          &#179;
                        </React.Fragment>
                      ),
                    },
                  ],
                },
              ],
            },
          ]
        : []),
    ],
  }

  return (
    <section className="mb-8 md:-mx-6 md:flex">
      <div className="md:w-4/6 md:px-6 mb-8 md:mb-0 w-full">
        <SectionTitle
          theme="violet"
          title={t('title')}
          subtitle={t('subtitle')}
        />
        <div className="bg-gray-nurse">
          {studyData.sections.map((section, index) => {
            const isLastSection = studyData.sections.length === index + 1

            return (
              <div className="pt-6" key={index}>
                {isRussianCalculator ? null : (
                  <h3
                    className="font-semibold my-6 px-6 text-2xl"
                    dangerouslySetInnerHTML={{
                      __html: section.title,
                    }}
                  />
                )}
                {section.rows.map((row, index) => {
                  return (
                    <div className={cx(row.className)} key={index}>
                      <div className="flex flex-col mb-6 px-6">
                        <p
                          className="font-bold mb-2 text-lg md:text-xl"
                          dangerouslySetInnerHTML={{
                            __html: row.title,
                          }}
                        />
                        {row.sub && <p>{row.sub}</p>}
                      </div>
                      <div className="-mx-4 md:flex justify-between">
                        {row.figures.map((figure, index) => {
                          return (
                            <div
                              className={cx(
                                'flex flex-1 mb-4 last:mb-0 md:mb-0 px-4',
                                figure.className
                              )}
                              key={index}
                            >
                              <div
                                className="flex flex-col flex-1 bg-gradient-to-r to-affair from-bossanova px-4 py-2 text-white"
                                key={index}
                              >
                                <p className="font-bold text-white text-xl">
                                  {figure.title}
                                </p>
                                <p className="flex flex-grow font-bold items-center text-aquamarine-blue md:text-5xl text-3xl">
                                  {figure.type === 'percent' && figure.value}
                                  {figure.type === 'currency' &&
                                    figure.value[activeCurrency.value]}
                                </p>
                                {figure.note && (
                                  <span
                                    className="text-lg"
                                    dangerouslySetInnerHTML={{
                                      __html: figure.note,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
                {!isLastSection && <hr className="mx-6" />}
              </div>
            )
          })}
        </div>
      </div>
      <div className="md:w-2/6 md:px-6 self-end w-full">
        <div className="px-4 md:px-0 md:mb-4">
          <Dentist className="mx-auto w-64" />
        </div>
      </div>
    </section>
  )
}

export default memo(LiftStudy)
