import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useLocaleDispatch } from '../../context/locale'

const SectionRow = ({ className, fields, label, sub, size }) => {
  const { control } = useFormContext()
  const { currencyFormat, numberFormat, percentFormat } = useLocaleDispatch()
  const { t } = useTranslation()

  const cellClass = (size) => {
    switch (size) {
      case 'large':
        return 'w-1/2'
      case 'regular':
      default:
        return 'w-1/3'
    }
  }

  if (!fields && !label) return null

  return (
    <div className="flex flex-wrap items-center mb-8 last:mb-0">
      <div className="bg-gray-nurse px-4 md:bg-transparent md:text-right mb-4 md:mb-0 md:py-0 md:w-2/6 py-4 text-center w-full">
        {label && <p className="font-semibold md:text-xl">{t(label)}</p>}
        {sub && <p className="italic text-sm">{t(sub)}</p>}
      </div>
      {fields && (
        <div className={cx('flex md:w-4/6 w-full', className)}>
          {fields.map(
            (
              { className, Component, name, readOnly = false, type = 'number' },
              index
            ) => {
              if (Component)
                return (
                  <div className={cx('px-3', cellClass(size))} key={index}>
                    <Component />
                  </div>
                )

              const formatValue = (value) => {
                switch (type) {
                  case 'currency':
                    return currencyFormat({ value })
                  case 'percent':
                    return percentFormat(value)
                  case 'text':
                    return value
                  case 'number':
                  default:
                    return numberFormat(value)
                }
              }

              return (
                <div className={cx('px-3', cellClass(size))} key={index}>
                  {readOnly ? (
                    <p
                      className={cx(
                        'font-semibold md:text-right md:text-xl px-3 py-2 rounded text-center w-full',
                        className
                      )}
                    >
                      {formatValue(name)}
                    </p>
                  ) : (
                    <Controller
                      name={name}
                      control={control}
                      onChange={([e]) => {
                        return parseInt(e.target.value, 10)
                      }}
                      as={
                        <input
                          type="number"
                          pattern="[0-9]*"
                          className={cx(
                            'border md:text-right md:text-xl px-4 py-2 rounded text-center w-full',
                            className
                          )}
                        />
                      }
                    />
                  )}
                </div>
              )
            }
          )}
        </div>
      )}
    </div>
  )
}

export default SectionRow
