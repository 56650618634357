import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Invisalign } from '../../../svg/invisalign.svg'
import { useLocaleState } from '../../../context/locale'

const Disclaimers = () => {
  const { activeCurrency, activeLanguage } = useLocaleState()
  const { i18n, t } = useTranslation('disclaimers')

  const isRussianCalculator = activeLanguage.value === 'ru'

  const showClaimsDisclaimer =
    !isRussianCalculator && !['mad'].includes(activeCurrency.value)

  const currentYear = new Date().getFullYear()

  return (
    <React.Fragment>
      <div className="-mx-8 mb-4 md:flex md:items-center">
        <div className="px-8">
          <p>{t('purpose.summary')}</p>
        </div>
        <div className="mt-8 md:mt-0 px-8">
          <Invisalign className="w-48" />
        </div>
      </div>
      {isRussianCalculator ? (
        <div className="mb-4">
          <p className="font-semibold mb-2 text-lg">{t('time.title')}</p>
          <ul className="px-2">
            <li className="mb-2">
              <p>{t('time.claims.one')}</p>
            </li>
            <li className="mb-2">
              <p>{t('time.disclaimer.one')}</p>
            </li>
          </ul>
          <ul className="px-2">
            <li className="mb-2">
              <p>{t('time.claims.two')}</p>
            </li>
            <li className="mb-2">
              <p>{t('time.disclaimer.two')}</p>
            </li>
          </ul>
        </div>
      ) : (
        <div className="mb-4">
          <p className="font-semibold mb-2 text-lg">{t('time.title')}</p>
          <p className="mb-2">{t('time.note')}</p>
        </div>
      )}
      <div className="mb-4">
        <p className="font-semibold mb-2 text-lg">{t('study.title')}</p>
        <ol className="list-decimal px-6">
          <li className="mb-2">
            <p>{t('study.notes.one')}</p>
          </li>
          <li className="mb-2">
            <p>{t('study.notes.two')}</p>
          </li>
          {i18n.exists('disclaimers:study.notes.five') && (
            <li className="mb-2">
              <p>{t('study.notes.five')}</p>
            </li>
          )}
        </ol>
      </div>
      {showClaimsDisclaimer && (
        <div className="mb-4">
          <p className="font-semibold mb-2 text-lg">{t('5d.title')}</p>
          <p className="mb-2">{t('5d.claims.title')}</p>
          <ul className="px-2">
            <li className="mb-2">
              <p>{t('5d.claims.one')}</p>
            </li>
            <li className="mb-2">
              <p>{t('5d.claims.two')}</p>
            </li>
            <li className="mb-2">
              <p>{t('5d.claims.three')}</p>
            </li>
          </ul>
          <p className="font-semibold mb-2">{t('5d.disclaimer.title')}</p>
          <ul className="px-2">
            <li className="mb-2">
              <p>{t('5d.disclaimer.one')}</p>
            </li>
            <li className="mb-2">
              <p>{t('5d.disclaimer.two')}</p>
            </li>
            <li className="mb-2">
              <p>{t('5d.disclaimer.three')}</p>
            </li>
          </ul>
        </div>
      )}

      {i18n.exists('disclaimers:reserves') && (
        <>
          <p className="mt-6">
            {t('reserves.marks.one')}
            {currentYear}
            {t('reserves.marks.two')}
          </p>
          <p className="mt-6">
            ©{currentYear} {t('reserves.rights')}
          </p>
        </>
      )}

      {!i18n.exists('disclaimers:reserves') && (
        <p className="mt-6">
          © Align Technology Switzerland GmbH {currentYear}
          <br />
          Invisalign®, the Invisalign logo, ClinCheck®, iTero®, Invisalign
          Teen®, SmartForce®, SmartTrack®, and Vivera®, among others, are
          trademarks and/or service marks of Align Technology, Inc. or one of
          its subsidiaries or affiliated companies and may be registered in the
          U.S. and/or other countries
          <br />
          <br />©{currentYear} All Rights Reserved. Align Technology Switzerland
          GmbH, Suurstoffi 22, 6343 Rotkreuz, Switzerland
        </p>
      )}
    </React.Fragment>
  )
}

export default Disclaimers
