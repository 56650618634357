import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import CalculatorFooter from '../CalculatorFooter'
import SectionRow from '../../SectionRow'
import SectionTitle from '../../SectionTitle'
import Select from '../../Select'
import TotalSection from '../../TotalSection'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleState } from '../../../context/locale'
import { useProjectedProductionData } from '../ProjectedProduction/hooks/useProjectedProductionData'
import { useTotalOwnershipData } from './hooks/useTotalOwnershipData'

const TotalOwnership = () => {
  const { activeCurrency, activeLanguage } = useLocaleState()
  const { t } = useTranslation(['ownership'])
  const { multiply } = useCalculator()
  const { setValue, watch } = useFormContext()
  const {
    average5dMonthlySavings,
    averageFlexMonthlySavings,
  } = useProjectedProductionData()
  const [activeYear, setActiveYear] = useState(1)

  const [activeSystem, setActiveSystem] = useState(
    activeCurrency.availableProducts[0]
  )
  const {
    calculateProfit,
    scannerSleevesTotal,
    totalCostOfOwnership,
  } = useTotalOwnershipData({
    activeSystem: activeSystem.value,
  })

  const formValues = watch([
    'restorative.volume',
    'restorative.volumeIncrease',
    'orthodontics.volume',
    'orthodontics.volumeIncrease',
    'invisalign.volumeIncrease',
    'caries.volume',
    'caries.volumeIncrease',
    'scannerSleeves.volume',
    'systemService.cost',
  ])

  const isLatamCalculator =
    activeLanguage.value === 'pt' || activeLanguage.value === 'es'
  const isRussianCalculator = activeLanguage.value === 'ru'

  const selectOptions = activeCurrency?.availableProducts

  useEffect(() => {
    return setActiveSystem(selectOptions[0])
  }, [selectOptions])

  const switchYear = (year) => {
    setValue('scannerSleeves.volume', 0)

    setActiveYear(year)
  }

  const latamTabOptions = [
    {
      label: t('ownership:tabs.options.one.label'),
      value: 1,
    },
    {
      label: t('ownership:tabs.options.two.label'),
      value: 2,
    },
    {
      label: t('ownership:tabs.options.three.label'),
      value: 3,
    },
    {
      label: t('ownership:tabs.options.four.label'),
      value: 4,
    },
    {
      label: t('ownership:tabs.options.five.label'),
      value: 5,
    },
  ]

  const defaultTabOptions = [
    {
      label: t('ownership:tabs.options.one.label'),
      value: 1,
    },
    {
      label: t('ownership:tabs.options.three.label'),
      value: 3,
    },
    {
      label: t('ownership:tabs.options.five.label'),
      value: 5,
    },
  ]

  const tabOptions = isLatamCalculator ? latamTabOptions : defaultTabOptions

  const totalOwnershipData = {
    rows: [
      ...(isRussianCalculator || ['mad'].includes(activeCurrency.value)
        ? []
        : [
            {
              label: t('ownership:table.rows.model.label'),
              fields: [
                {
                  Component: () => (
                    <Select
                      defaultValue={selectOptions[0]}
                      onChange={(value) => setActiveSystem(value)}
                      options={selectOptions}
                      value={selectOptions?.find(
                        (option) => option.value === activeSystem.value
                      )}
                    />
                  ),
                },
              ],
            },
          ]),
      {
        className: 'justify-between',
        label: t('ownership:table.rows.service.label'),
        fields: [
          {
            name: 'systemService.cost',
          },
          {
            name: formValues['systemService.cost'] || 0,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        className: 'justify-between',
        label: t('ownership:table.rows.sleeves.label'),
        sub: t('ownership:table.rows.sleeves.sub'),
        fields: [
          {
            name: 'scannerSleeves.volume',
          },
          {
            name: scannerSleevesTotal,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        className: 'justify-end',
        label: t('ownership:table.rows.total.label'),
        fields: [
          {
            className: 'text-port-gore md:text-3xl',
            name: totalCostOfOwnership,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  const financeFiguresData = {
    elementflex: {
      columns: [
        {
          label: t('ownership:grid.columns.payback.label'),
          value: Math.round(
            totalCostOfOwnership / averageFlexMonthlySavings || 0
          ),
        },
        {
          label: t('ownership:grid.columns.profit.label'),
          value: calculateProfit(activeYear),
          type: 'currency',
        },
        {
          label: t('ownership:grid.columns.roi.label'),
          value: Math.round(
            multiply([calculateProfit(activeYear) / totalCostOfOwnership, 100])
          ),
          type: 'percent',
        },
      ],
    },
    element5d: {
      columns: [
        {
          label: t('ownership:grid.columns.payback.label'),
          value: Math.round(
            totalCostOfOwnership / average5dMonthlySavings || 0
          ),
        },
        {
          label: t('ownership:grid.columns.profit.label'),
          value: calculateProfit(activeYear),
          type: 'currency',
        },
        {
          label: t('ownership:grid.columns.roi.label'),
          value: Math.round(
            multiply([calculateProfit(activeYear) / totalCostOfOwnership, 100])
          ),
          type: 'percent',
        },
      ],
    },
  }

  const parseActiveSystem = [
    'element2',
    'elementflex',
    'element2plusmobile',
    'element2pluscart',
    'element5dpluslitemobile',
    'element5dpluslitecart',
  ].includes(activeSystem.value)
    ? 'elementflex'
    : 'element5d'

  return (
    <React.Fragment>
      <section className="mb-8 md:-mx-6 md:flex">
        <div className="md:w-4/6 md:px-6 w-full">
          <SectionTitle theme="violet" step={4} title={t('ownership:title')} />
          {isRussianCalculator ? null : (
            <div className="-mx-1 flex mt-2">
              {tabOptions.map((option, index) => {
                const isActive = activeYear === option.value

                return (
                  <button
                    className={cx(
                      'flex-1 font-bold mx-1 py-4 text-2xl focus:outline-none',
                      {
                        'bg-port-gore text-white': isActive,
                        'bg-pewter text-port-gore': !isActive,
                      }
                    )}
                    onClick={() => switchYear(option.value)}
                    key={index}
                  >
                    {option.label}
                  </button>
                )
              })}
            </div>
          )}
          <form className="flex flex-col mb-8 mt-12">
            {totalOwnershipData.rows &&
              totalOwnershipData.rows.map((row, index) => (
                <SectionRow {...row} key={index} />
              ))}
          </form>
          <TotalSection
            columns={financeFiguresData[parseActiveSystem].columns}
            title={activeSystem.label}
            theme={parseActiveSystem === 'element5d' ? 'gray' : 'turqoise'}
          />
        </div>
      </section>
      <CalculatorFooter
        activeSystem={parseActiveSystem}
        activeYear={activeYear}
      />
    </React.Fragment>
  )
}

export default TotalOwnership
