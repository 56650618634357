import React from 'react'
import { useTranslation } from 'react-i18next'

import SectionRow from '../../../SectionRow'
import SectionTitle from '../../../SectionTitle'

const YearSection = ({ rows, title, years = 1 }) => {
  const { t } = useTranslation()

  return (
    <div>
      <SectionTitle title={t(title, { count: years })} />
      <form className="border-b flex flex-col mb-8 pb-4">
        {rows && rows.map((row, index) => <SectionRow {...row} key={index} />)}
      </form>
    </div>
  )
}

export default YearSection
