const languages = [
  {
    locale: 'en-GB',
    regions: ['eu', 'na'],
    defaultCurrency: 'eur',
    value: 'en',
    label: 'English',
  },
  {
    locale: 'fr-FR',
    regions: ['eu'],
    defaultCurrency: 'eur',
    value: 'fr',
    label: 'French',
  },
  {
    locale: 'de-DE',
    regions: ['eu'],
    defaultCurrency: 'eur',
    value: 'de',
    label: 'German',
  },
  {
    locale: 'it-IT',
    regions: ['eu'],
    defaultCurrency: 'eur',
    value: 'it',
    label: 'Italian',
  },
  {
    locale: 'pl-PL',
    regions: ['eu'],
    defaultCurrency: 'eur',
    value: 'pl',
    label: 'Polish',
  },
  {
    locale: 'es-ES',
    regions: ['eu'],
    defaultCurrency: 'eur',
    value: 'es',
    label: 'Spanish',
  },
  {
    locale: 'nl-NL',
    regions: ['eu'],
    defaultCurrency: 'eur',
    value: 'nl',
    label: 'Dutch',
  },
  {
    locale: 'ru-RU',
    regions: ['eu'],
    defaultCurrency: 'rub',
    value: 'ru',
    label: 'Russian',
  },
  {
    locale: 'pt-BR',
    regions: ['latam'],
    defaultCurrency: 'brl',
    value: 'pt',
    label: 'Portuguese',
  },
  {
    locale: 'es-AR',
    regions: ['latam'],
    defaultCurrency: 'ars',
    value: 'es',
    label: 'Spanish',
  },
  {
    locale: 'en-ZA',
    regions: ['latam'],
    defaultCurrency: 'brl',
    value: 'en',
    label: 'English',
  },
]

export { languages }
