import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import 'core-js/es6'

import App from './components/App'
import * as serviceWorker from './serviceWorker'

import './i18n'
import './tailwind.css'

ReactDOM.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>,
  document.getElementById('root')
)

// hotjar.initialize(process.env.REACT_APP_HOTJAR_SITE_ID, 6)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (window.location && window.location.search.indexOf('?d=') >= 0) {
  serviceWorker.unregister()
} else {
  serviceWorker.register()
}
