import React, { useState, useEffect } from 'react'
import { ErrorMessage, useFormContext } from 'react-hook-form'

import FormError from './Error'
import FormLabel from './Label'
import Select from '../Select'

const FormSelect = ({
  disabled = false,
  label,
  name,
  options,
  placeholder,
}) => {
  const { errors, register, setValue } = useFormContext()
  const [value, setReactSelect] = useState(null)

  const handleChange = (option) => {
    setValue(name, option.value)
    setReactSelect(option)
  }

  useEffect(() => {
    register({ name })
  }, [name, register])

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        {label && <FormLabel label={label} for={name} />}
        <Select
          name={name}
          placeholder={placeholder}
          options={options}
          value={value}
          disabled={disabled}
          onChange={handleChange}
        />
      </div>
      <ErrorMessage as={<FormError />} name={name} errors={errors} />
    </div>
  )
}

export default FormSelect
