import React, { createContext, useReducer, useContext } from 'react'

const CalculatorDispatchContext = createContext()
const CalculatorStateContext = createContext()

const initialValues = {
  crown: {
    fee: 1500,
    perUnit: 12,
    revenueIncrease: 1500,
    volume: 230,
    volumeIncrease: 0,
  },
  implants: {
    fee: 2000,
    perUnit: 18,
    revenueIncrease: 2000,
    volume: 15,
    volumeIncrease: 0,
  },
  night: {
    fee: 500,
    perUnit: 12,
    revenueIncrease: 500,
    volume: 60,
    volumeIncrease: 0,
  },
  orthodontics: {
    fee: 4000,
    perUnit: 20,
    revenueIncrease: 4000,
    volume: 2,
    volumeIncrease: 2,
  },
  remakes: {
    fee: 0,
    perUnit: 18,
    volume: 40,
  },
  scanner: {
    cad: {
      oneYear: 43250,
      fiveYear: 43250,
      sleeveFee: 4.04,
    },
    usd: {
      oneYear: 33000,
      fiveYear: 33000,
      sleeveFee: 3.08,
    },
  },
  service: {
    cad: {
      fiveYear: 22560,
    },
    usd: {
      fiveYear: 17280,
    },
  },
  storage: {
    fee: 0,
    perUnit: 1000,
    volume: 0,
  },
  veneers: {
    fee: 1200,
    perUnit: 20,
    revenueIncrease: 1200,
    volume: 12,
    volumeIncrease: 0,
  },
}

const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'RESET':
      return initialValues
    case 'UPDATE':
      return {
        crown: {
          ...state.crown,
          ...payload.crown,
        },
        implants: {
          ...state.implants,
          ...payload.implants,
        },
        night: {
          ...state.night,
          ...payload.night,
        },
        orthodontics: {
          ...state.orthodontics,
          ...payload.orthodontics,
        },
        remakes: {
          ...state.remakes,
          ...payload.remakes,
        },
        scanner: {
          cad: {
            ...state?.scanner?.cad,
            ...payload?.scanner?.cad,
          },
          usd: {
            ...state?.scanner?.usd,
            ...payload?.scanner?.usd,
          },
        },
        service: {
          cad: {
            ...state?.service?.cad,
            ...payload?.service?.cad,
          },
          usd: {
            ...state?.service?.usd,
            ...payload?.service?.usd,
          },
        },
        storage: {
          ...state.storage,
          ...payload.storage,
        },
        veneers: {
          ...state.veneers,
          ...payload.veneers,
        },
      }
    default:
      throw new Error(`Invalid action: ${type}`)
  }
}

const CalculatorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const reset = () => dispatch({ type: 'RESET' })
  const update = (payload) => dispatch({ payload, type: 'UPDATE' })

  return (
    <CalculatorStateContext.Provider value={{ ...state, initialValues }}>
      <CalculatorDispatchContext.Provider
        value={{ resetState: reset, updateState: update }}
      >
        {children}
      </CalculatorDispatchContext.Provider>
    </CalculatorStateContext.Provider>
  )
}

const useCalculatorDispatch = () => {
  const context = useContext(CalculatorDispatchContext)

  if (!context)
    throw new Error(
      'useCalculatorDispatch must be used within a CalculatorProvider'
    )

  return context
}

const useCalculatorState = () => {
  const context = useContext(CalculatorStateContext)

  if (!context)
    throw new Error(
      'useCalculatorState must be used within a CalculatorProvider'
    )

  return context
}

export { CalculatorProvider, useCalculatorDispatch, useCalculatorState }
