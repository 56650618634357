import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useLocaleState } from '../../context/locale'
import Select from '../Select'

const Header = ({ resetForm }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const {
    activeCurrency,
    activeLanguage,
    activeRegionPath,
    availableCurrencies,
    availableLanguages,
  } = useLocaleState()
  const { t } = useTranslation()

  const showSelectLocalSelects = !['/', '/report'].includes(pathname)

  const updatePath = (path) => {
    if (resetForm) resetForm()

    history.push(path)
  }

  return (
    <header className={cx('py-8', 'bg-gradient-to-r to-affair from-bossanova')}>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between max-w-screen-xl mx-auto px-4 md:space-x-4 md:px-6">
        <h2 className="font-bold text-white text-2xl md:text-4xl">
          {t('header.title')}
        </h2>
        {showSelectLocalSelects && (
          <div className="flex flex-none mt-4 space-x-4 md:mt-0">
            {availableLanguages.length > 1 && (
              <Select
                className="w-32"
                value={activeLanguage}
                options={availableLanguages}
                onChange={({ value }) =>
                  updatePath(`/${activeRegionPath}?language=${value}`)
                }
              />
            )}
            <Select
              className="w-32"
              value={activeCurrency}
              options={availableCurrencies}
              onChange={({ value }) =>
                updatePath(
                  `/${activeRegionPath}?language=${activeLanguage.value}&currency=${value}`
                )
              }
            />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
