import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { useCalculatorDispatch } from '../../../context/calculator'

const Nav = ({ routes }) => {
  const { updateState } = useCalculatorDispatch()
  const { handleSubmit } = useFormContext()
  const history = useHistory()
  const { pathname } = useLocation()

  const onSubmit = ({ data, route }) => {
    updateState(data)

    history.push(route)
  }

  return (
    <nav className="mb-8">
      <ul className="md:flex">
        {routes.map((route, index) => {
          const isActive = route.path === pathname

          return (
            <li className="flex flex-1" key={route.path}>
              <button
                className={cx(
                  'bg-port-gore px-4 py-3 text-center text-xl text-white w-full hover:text-white',
                  { 'bg-opacity-50 font-bold pointer-events-none': isActive }
                )}
                onClick={handleSubmit((data) =>
                  onSubmit({ data, route: route.path })
                )}
              >
                {`${index + 1}. ${route.title}`}
              </button>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Nav
