import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SectionHeader from '../../SectionHeader'
import SectionRow from '../../SectionRow'
import SectionTitle from '../../SectionTitle'
import SummaryCircle from '../../SummaryCircle'
import { useCalculator } from '../../../hooks/useCalculator'
import { useLocaleDispatch } from '../../../context/locale'

const DentalProcedures = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()
  const { currencyFormat } = useLocaleDispatch()
  const { t } = useTranslation('treatments')

  const formValues = watch([
    'crown.volume',
    'crown.fee',
    'implants.volume',
    'implants.fee',
    'night.volume',
    'night.fee',
    'veneers.volume',
    'veneers.fee',
    'orthodontics.volume',
    'orthodontics.fee',
  ])

  const crownProduction = multiply([
    formValues['crown.volume'],
    formValues['crown.fee'],
  ])

  const implantsProduction = multiply([
    formValues['implants.volume'],
    formValues['implants.fee'],
  ])

  const nightProduction = multiply([
    formValues['night.volume'],
    formValues['night.fee'],
  ])

  const veneersProduction = multiply([
    formValues['veneers.volume'],
    formValues['veneers.fee'],
  ])

  const orthodonticsProduction = multiply([
    formValues['orthodontics.volume'],
    formValues['orthodontics.fee'],
  ])

  const totalAnnualProduction = sum([
    crownProduction,
    implantsProduction,
    nightProduction,
    veneersProduction,
    orthodonticsProduction,
  ])

  const dentalProceduresData = {
    headers: {
      fields: [
        {
          label: t('table.header.quantity.label'),
        },
        {
          label: t('table.header.fee.label'),
        },
        {
          label: t('table.header.production.label'),
        },
      ],
    },
    rows: [
      {
        label: t('table.rows.crown.label'),
        fields: [
          {
            name: 'crown.volume',
          },
          {
            name: 'crown.fee',
          },
          {
            name: crownProduction,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('table.rows.implants.label'),
        fields: [
          {
            name: 'implants.volume',
          },
          {
            name: 'implants.fee',
          },
          {
            name: implantsProduction,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('table.rows.night.label'),
        fields: [
          {
            name: 'night.volume',
          },
          {
            name: 'night.fee',
          },
          {
            name: nightProduction,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('table.rows.veneers.label'),
        fields: [
          {
            name: 'veneers.volume',
          },
          {
            name: 'veneers.fee',
          },
          {
            name: veneersProduction,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
      {
        label: t('table.rows.orthodontics.label'),
        fields: [
          {
            name: 'orthodontics.volume',
          },
          {
            name: 'orthodontics.fee',
          },
          {
            name: orthodonticsProduction,
            readOnly: true,
            type: 'currency',
          },
        ],
      },
    ],
  }

  return (
    <main>
      <div className="lg:flex flex-1 items-center justify-between">
        <div className="w-full lg:w-3/4">
          <SectionTitle step={1} title={t('title')} theme="transparent" />
        </div>
      </div>
      <div className="lg:-mx-6 lg:flex">
        <div className="lg:w-4/6 lg:px-6 mb-8 lg:mb-0 w-full">
          <form className="flex flex-col lg:border-t lg:pt-4">
            {dentalProceduresData.headers && (
              <SectionHeader headers={dentalProceduresData.headers} />
            )}
            {dentalProceduresData.rows &&
              dentalProceduresData.rows.map((row, index) => (
                <SectionRow {...row} key={index} />
              ))}
          </form>
        </div>
        <div className="lg:w-2/6 lg:px-6 w-full">
          <SummaryCircle
            label={t('summary.production.label')}
            value={currencyFormat({ value: totalAnnualProduction })}
          />
        </div>
      </div>
    </main>
  )
}

export default DentalProcedures
