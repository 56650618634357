import React from 'react'
import cx from 'classnames'

const Button = ({ children, disabled = false, onClick, theme = 'purple' }) => {
  const themeClass = (theme) => {
    switch (theme) {
      case 'transparent':
        return 'bg-transparent text-port-gore'
      case 'purple':
      default:
        return 'bg-port-gore text-white'
    }
  }

  return (
    <button
      className={cx(
        'font-semibold px-5 py-3 rounded-lg md:text-lg',
        themeClass(theme),
        {
          'opacity-50': disabled,
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
