import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import SectionTitle from '../../SectionTitle'
import TotalProduction from './TotalProduction'
import { useLocaleDispatch, useLocaleState } from '../../../context/locale'
import VolumeIncrease from './VolumeIncrease'
import SummaryCircle from '../../SummaryCircle'
import { useCalculator } from '../../../hooks/useCalculator'
import { useProjectedProductionData } from './hooks/useProjectedProductionData'

const ProjectedProduction = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()
  const { currencyFormat } = useLocaleDispatch()
  const { activeCurrency, activeLanguage } = useLocaleState()

  const {
    additionalCariesRevenue,
    restorativeIncrease,
    orthodonticsIncrease,
    invisalignIncrease,
    projectedAnnualIncrease,
    average5dMonthlySavings,
    averageFlexMonthlySavings,
  } = useProjectedProductionData()
  const { t } = useTranslation(['production', 'products'])

  const formValues = watch([
    'restorative.volume',
    'restorative.volumeIncrease',
    'orthodontics.volume',
    'orthodontics.volumeIncrease',
    'invisalign.volume',
    'invisalign.volumeIncrease',
    'caries.volume',
    'caries.volumeIncrease',
  ])
  const { pathname } = useLocation()
  const [, activeRegionPath] = pathname.split('/')

  const isRussianCalculator =
    activeLanguage.value === 'ru' || ['mad'].includes(activeCurrency.value)
  const isLatamCalculator = activeRegionPath === 'latam'

  const defaultRows = [
    {
      label: t('production:sections.volume.table.rows.restorative.label'),
      fields: [
        {
          name: 'restorative.volumeIncrease',
        },
        {
          name: restorativeIncrease,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['restorative.volumeIncrease'] /
                formValues['restorative.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
    {
      label: t('production:sections.volume.table.rows.orthodontics.label'),
      fields: [
        {
          name: 'orthodontics.volumeIncrease',
        },
        {
          name: orthodonticsIncrease,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['orthodontics.volumeIncrease'] /
                formValues['orthodontics.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
    {
      label: t('production:sections.volume.table.rows.invisalign.label'),
      fields: [
        {
          name: 'invisalign.volumeIncrease',
        },
        {
          name: invisalignIncrease,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['invisalign.volumeIncrease'] /
                formValues['invisalign.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
    ...(isRussianCalculator
      ? []
      : [
          {
            label: t('production:sections.volume.table.rows.treatment.label'),
            fields: [
              {
                name: 'caries.volumeIncrease',
              },
              {
                name: additionalCariesRevenue,
                readOnly: true,
                type: 'currency',
              },
              {
                name: Math.round(
                  multiply([
                    formValues['caries.volumeIncrease'] /
                      formValues['caries.volume'],
                    100,
                  ])
                ),
                readOnly: true,
                type: 'percent',
              },
            ],
          },
        ]),
  ]

  const latamRows = [
    {
      label: t('production:sections.volume.table.rows.treatment.label'),
      fields: [
        {
          name: 'caries.volumeIncrease',
        },
        {
          name: additionalCariesRevenue,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['caries.volumeIncrease'] / formValues['caries.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
    {
      label: t('production:sections.volume.table.rows.restorative.label'),
      fields: [
        {
          name: 'restorative.volumeIncrease',
        },
        {
          name: restorativeIncrease,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['restorative.volumeIncrease'] /
                formValues['restorative.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
    {
      label: t('production:sections.volume.table.rows.invisalign.label'),
      fields: [
        {
          name: 'invisalign.volumeIncrease',
        },
        {
          name: invisalignIncrease,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['invisalign.volumeIncrease'] /
                formValues['invisalign.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
    {
      label: t('production:sections.volume.table.rows.orthodontics.label'),
      fields: [
        {
          name: 'orthodontics.volumeIncrease',
        },
        {
          name: orthodonticsIncrease,
          readOnly: true,
          type: 'currency',
        },
        {
          name: Math.round(
            multiply([
              formValues['orthodontics.volumeIncrease'] /
                formValues['orthodontics.volume'],
              100,
            ])
          ),
          readOnly: true,
          type: 'percent',
        },
      ],
    },
  ]

  const volumeIncreaseData = {
    headers: {
      fields: [
        {
          label: t('production:sections.volume.table.header.volume.label'),
        },
        {
          label: t('production:sections.volume.table.header.revenue.label'),
        },
        {
          label: t('production:sections.volume.table.header.change.label'),
        },
      ],
    },
    rows: isLatamCalculator ? latamRows : defaultRows,
  }

  const availableProducts = activeCurrency?.availableProducts

  const totalProductionData = {
    headers: {
      fill: true,
      fields: [
        {
          className: 'text-port-gore md:text-base',
          labels: availableProducts?.reduce(
            (a, { label, premium }) => [
              ...a,
              ...(premium ? [] : [{ label: t(label) }]),
            ],
            []
          ),
        },
        {
          className: 'text-turqoise md:text-base',
          labels: availableProducts?.reduce(
            (a, { label, premium }) => [
              ...a,
              ...(premium ? [{ label: t(label) }] : []),
            ],
            []
          ),
        },
      ],
    },
    rows: [
      {
        label: t('production:sections.total.table.rows.projected.label'),
        fields: [
          {
            className: 'text-port-gore md:text-3xl',
            name: projectedAnnualIncrease,
            readOnly: true,
            type: 'currency',
          },
          ...(isRussianCalculator
            ? []
            : [
                {
                  className: 'text-turqoise md:text-3xl',
                  name: sum([projectedAnnualIncrease, additionalCariesRevenue]),
                  readOnly: true,
                  type: 'currency',
                },
              ]),
        ],
      },
      {
        label: t('production:sections.total.table.rows.average.label'),
        fields: [
          {
            name: projectedAnnualIncrease / 12,
            readOnly: true,
            type: 'currency',
          },
          ...(isRussianCalculator
            ? []
            : [
                {
                  name:
                    (projectedAnnualIncrease + additionalCariesRevenue) / 12,
                  readOnly: true,
                  type: 'currency',
                },
              ]),
        ],
      },
    ],
    size: 'large',
  }

  const summaryCircles = [
    {
      label: t('production:summary.flex.label'),
      icon: 'piggy',
      note: availableProducts
        .reduce(
          (a, { label, premium }) => [...a, ...(premium ? [] : [t(label)])],
          []
        )
        .join(', '),
      value: currencyFormat({ value: averageFlexMonthlySavings }),
    },
    ...(isRussianCalculator
      ? []
      : [
          {
            icon: 'piggy',
            label: t('production:summary.5d.label'),
            note: availableProducts
              .reduce(
                (a, { label, premium }) => [
                  ...a,
                  ...(premium ? [t(label)] : []),
                ],
                []
              )
              .join(', '),
            value: currencyFormat({ value: average5dMonthlySavings }),
          },
        ]),
  ]

  return (
    <section className="mb-8 md:-mx-6 md:flex">
      <div className="md:w-4/6 md:px-6 mb-8 md:mb-0 w-full">
        <SectionTitle theme="violet" step={3} title={t('production:title')} />
        <VolumeIncrease {...volumeIncreaseData} />
        <TotalProduction {...totalProductionData} />
      </div>
      <div className="md:w-2/6 md:px-6 self-start w-full">
        {summaryCircles &&
          summaryCircles.map((summary, index) => (
            <SummaryCircle
              {...summary}
              theme="violet"
              className="mb-4"
              key={index}
            />
          ))}
      </div>
    </section>
  )
}

export default ProjectedProduction
