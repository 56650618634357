import React from 'react'
import { number, oneOf, string } from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

const SectionTitle = ({ step, subtitle, theme = 'purple', title }) => {
  const { t } = useTranslation()

  const themeClass = (theme) => {
    switch (theme) {
      case 'gray':
        return 'bg-gray-nurse text-port-gore'
      case 'turqoise':
        return 'bg-turqoise text-starship'
      case 'yellow':
        return 'bg-starship text-turqoise'
      case 'transparent':
        return 'bg-transparent text-black'
      case 'violet':
        return 'bg-gradient-to-r to-affair from-bossanova text-aquamarine-blue'
      case 'purple':
      default:
        return 'bg-port-gore text-turqoise'
    }
  }

  return (
    <header className={cx('px-2 py-4 w-full', themeClass(theme))}>
      <h2 className="font-bold text-2xl">
        {step && (
          <span
            className={cx('mr-1', {
              'text-white': theme !== 'transparent',
              'text-black': theme === 'transparent',
            })}
          >
            {t('section.title.step')} {step}:
          </span>
        )}
        {title}
      </h2>
      {subtitle && (
        <p
          className={cx('mr-1', {
            'text-white': theme !== 'transparent',
            'text-black': theme === 'transparent',
          })}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </header>
  )
}

SectionTitle.propTypes = {
  step: number,
  subtitle: string,
  theme: oneOf(['gray', 'purple', 'transparent', 'turqoise', 'violet', 'yellow']),
  title: string.isRequired,
}

export default SectionTitle
