import { useFormContext } from 'react-hook-form'

import { useCalculator } from '../../../../hooks/useCalculator'

const useProjectedSavingsData = () => {
  const { multiply, sum } = useCalculator()
  const { watch } = useFormContext()

  const formValues = watch([
    'alginate.perUnit',
    'alginate.volume',
    'silicone.perUnit',
    'silicone.volume',
    'storage.costs',
    'models.perUnit',
    'models.volume',
    'models.editable',
    'time.perUnit',
    'remakes.volume',
    'hourlyRate.perUnit',
  ])

  const annualImpressionsVolume = sum([
    formValues['alginate.volume'],
    formValues['silicone.volume'],
  ])

  const annualImpressionsMinutes = multiply([
    formValues['time.perUnit'],
    annualImpressionsVolume,
  ])

  const annualRemakesMinutes = multiply([
    formValues['time.perUnit'],
    formValues['remakes.volume'],
  ])

  const annualImpressionsHours = Math.round(
    sum([annualImpressionsMinutes, annualRemakesMinutes]) / 60
  )

  const modelsVolume = formValues['models.editable']
    ? formValues['models.volume']
    : annualImpressionsVolume

  const totalCosts = sum([
    multiply([formValues['alginate.perUnit'], formValues['alginate.volume']]),
    multiply([formValues['silicone.perUnit'], formValues['silicone.volume']]),
    multiply([formValues['models.perUnit'], modelsVolume]),
    formValues['storage.costs'],
  ])

  const totalImpressionsCosts = sum([
    multiply([annualImpressionsHours, formValues['hourlyRate.perUnit']]),
    totalCosts,
  ])

  const timeSaved = multiply([annualImpressionsHours, 0.59])

  const totalProjectedAnnualSavings = sum([
    totalCosts,
    multiply([timeSaved, formValues['hourlyRate.perUnit']]),
  ])

  return {
    annualImpressionsVolume,
    annualImpressionsHours,
    totalCosts,
    totalImpressionsCosts,
    totalProjectedAnnualSavings,
    timeSaved,
  }
}

export { useProjectedSavingsData }
