module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    extend: {
      borderWidth: {
        14: '14px',
      },
      colors: {
        affair: '#8c4799',
        'aquamarine-blue': '#6FE0D7',
        bossanova: '#3f2a56',
        'gray-nurse': '#F5F6F5',
        pewter: '#A3A5A3',
        'port-gore': '#2F1E44',
        starship: '#F1E54F',
        turqoise: '#29CABE',
        'mint-tulip': '#B3EFEB',
      },
      spacing: {
        '72': '18rem',
      },
    },
  },
  variants: {
    borderRadius: ['responsive', 'first', 'last'],
    margin: ['responsive', 'hover', 'last'],
  },
  plugins: [],
}
